import * as ActionTypes from "./types";
import { list_saved_picks } from "../../api/utilities/data";


export function picks__list()
{
    //console.log("Actions: categories_list_all");
    var saved_picks = list_saved_picks();

    return {
        type: ActionTypes.PICKS__LIST__SUCCESS,
        payload: saved_picks
    };
}


export function picks__list_game_picks(game)
{
    //console.log("Actions: categories_list_all");
    var saved_picks = list_saved_picks();

    return {
        type: ActionTypes.PICKS__LIST__SUCCESS,
        payload: saved_picks
    };
}