//import { get_default_game } from "./entities/game";
import games_json from '../../json/games.json';
import * as globals from "../../globals";
//console.log(games_json);


export function get_pick_method_name(pick_method_id) {
    //console.log("get_pick_method_name", pick_method_id);

    if (pick_method_id === 0) {
        return "Overall";
    }
    else if (pick_method_id === -1) {
        return "Shared Top Numbers";
    }
    else {
        let pick_methods = globals.get__pick_methods();
        let pick_method = pick_methods.find(x => x.pick_method_id === pick_method_id);
        //console.log(pick_method);

        if (pick_method) {
            return pick_method.title;
        }
        else {
            throw Error("Pick Method Not Found: " + pick_method_id);
        }
    }
    // else if (pick_method_id === 1)
    // {
    //     pick_method = "Quick Pick";
    // }
    // else if (pick_method_id === 2)
    // {
    //     pick_method = "Tap Pick";
    // }
    // else if (pick_method_id === 3)
    // {
    //     pick_method = "Blind Pick";
    // }
    // else if (pick_method_id === 4)
    // {
    //     pick_method = "Release to Pick";
    // }
    // else if (pick_method_id === 6)
    // {
    //     pick_method = "Tap Pick Infinite";
    // }
    // else
    // {
    //     throw "Pick Method Not Found: " + pick_method_id;
    // }


    //console.log("get_pick_method_name: result", pick_method);
    //return pick_method;
}


export function get_game_by_name(game_name) {
    for (let x = 0; x < games_json.length; x++) {
        if (games_json[x].name.toLocaleLowerCase() === game_name.toLocaleLowerCase()) {
            return games_json[x];
        }
    }


    return null;
}


export function get_game_by_id(game_id) {
    //console.log("get_game_by_id", game_id);

    let games = games_json;
    for (let x = 0; x < games.length; x++) {
        //console.log(game_id, games[x].id);
        if (games[x].id * 1 === game_id * 1) {
            return games[x];
        }
    }


    return null;
}


export function get_random_picks(game) {
    // console.log(game);
    // console.log(game.first_line);
    // console.log(game.first_line.number_of_picks);
    // console.log(game.first_line.unique_numbers);
    // console.log(game.second_line);
    // console.log(game.second_line.number_of_picks);
    // console.log(game.second_line.unique_numbers);    


    //pick number set of numbers
    let first_line_picks = [];
    let second_line_picks = [];

    //get random numbers based on the game
    var rnd_numbers = get_random_numbers_by_game(game);
    //console.log(rnd_numbers);

    //take numbers from the rnd lists created
    //rnd_numbers.first_line_numbers
    if (game.first_line) {
        if (game.first_line.number_of_picks > 0) {
            if (game.first_line.unique_numbers) {
                //console.log("first line unique");
                //if unique, take the first N picks
                for (let pick_index = 0; pick_index < game.first_line.number_of_picks; pick_index++) {
                    first_line_picks.push(rnd_numbers.first_line_numbers[pick_index]);

                    //sort
                    first_line_picks = first_line_picks.sort((a, b) => a < b ? -1 : 1);
                }
            }
            else {
                //console.log("first line NOT-unique");

                //if no-unique, loop through getting rand numbers until we have enough numbers
                //take first number after each loop
                while (first_line_picks.length < game.first_line.number_of_picks) {
                    rnd_numbers = get_random_numbers_by_game(game);
                    first_line_picks.push((rnd_numbers.first_line_numbers[0]));
                }

                //no sort, order matters
            }
        }
    }


    //rnd_numbers.second_line_numbers
    if (game.second_line) {
        if (game.second_line.number_of_picks > 0) {
            if (game.second_line.unique_numbers) {
                //console.log("second line unique");

                //if unique, take the first N picks
                for (let pick_index = 0; pick_index < game.second_line.number_of_picks; pick_index++) {
                    second_line_picks.push(rnd_numbers.second_line_numbers[pick_index]);

                    //sort
                    second_line_picks = second_line_picks.sort((a, b) => a < b ? -1 : 1);
                }
            }
            else {
                //console.log("second line NOT unique");

                //if no-unique, loop through getting rand numbers until we have enough numbers
                while (second_line_picks.length < game.second_line.number_of_picks) {
                    rnd_numbers = get_random_numbers_by_game(game);
                    second_line_picks.push((rnd_numbers.second_line_numbers[0]));
                }

                //no sort, order matters
            }
        }
    }


    return {
        first_line_picks: first_line_picks,
        second_line_picks: second_line_picks,
    };
}


export function get_random_numbers_by_game(game) {
    //console.log("get_random_numbers_by_game", game);

    var rnd_numbers = {};
    let first_line_numbers_obj = [];
    let second_line_numbers_obj = [];


    for (let x = game.first_line.range_start; x <= game.first_line.range_end; x++) {
        first_line_numbers_obj.push({ number: x, rnd: Math.random() });
    }

    for (let x = game.second_line.range_start; x <= game.second_line.range_end; x++) {
        second_line_numbers_obj.push({ number: x, rnd: Math.random() });
    }

    // console.log(first_line_numbers_obj);
    // console.log(second_line_numbers_obj);

    first_line_numbers_obj = first_line_numbers_obj.sort((a, b) => (a.rnd >= b.rnd) ? 1 : -1);
    second_line_numbers_obj = second_line_numbers_obj.sort((a, b) => (a.rnd >= b.rnd) ? 1 : -1);

    // console.log(first_line_numbers_obj);
    // console.log(second_line_numbers_obj);


    let first_line_numbers = [];
    let second_line_numbers = [];
    for (let x = 0; x < first_line_numbers_obj.length; x++) {
        first_line_numbers.push(first_line_numbers_obj[x].number);
    }
    for (let x = 0; x < second_line_numbers_obj.length; x++) {
        second_line_numbers.push(second_line_numbers_obj[x].number);
    }


    //return obj
    rnd_numbers.first_line_numbers = first_line_numbers;
    rnd_numbers.second_line_numbers = second_line_numbers;


    return rnd_numbers;
}


export function list_default_games() {
    return games_json;
}


export function is_playing_today(game) {
    //console.log("is_playing_today", game);

    if (!game) {
        return false;
    }


    if (game.drawing_days_javascript) {
        let drawing_days_array = game.drawing_days_javascript.split(",");
        // console.log(drawing_days_array);
        // console.log("todays day: " + new Date().getDay());
        for (let drawing_day of drawing_days_array) {
            if (drawing_day * 1 === new Date().getDay()) {
                //console.log("Game plays today");
                return true;
            }
        }
    }


    return false;
}