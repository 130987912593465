let server_pick =
{    
    pick: {},
    is_test_device: false,        
    platform: "",
    game_id: 0,
    game_type: "", //same as game name
    game_name: "",        
    pick_method: "", //name of pick type, not id
    device_id: "",
    version: 2,
};

export function get_default_server_pick()
{
    //return game;
    return JSON.parse(JSON.stringify(server_pick))
}