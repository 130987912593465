
import { Link } from "react-router-dom";

import "./footer.scss";

export default function Footer()
{
    //console.log("footer");

    return (
        <footer>
            <div>
                <Link to="/games/" alt="Pick a Game" title="Pick a Game"><img alt="header logo" title="header logo" src="/images/logo512.png" /></Link>
            </div>
            <div className="links">
                <a href="https://play.google.com/store/apps/details?id=com.lhmgapps.luckylottonumberpicker" target="_blank" rel="noreferrer" alt="com.lhmgapps.luckylottonumberpicker" title="com.lhmgapps.luckylottonumberpicker">
                    <img src="/images/mobile-stores--android.png" alt="Lucky Lotto Number Picker in the Google Play Store" title="Lucky Lotto Number Picker in the Google Play Store" />
                </a>

                <a href="https://apps.apple.com/us/app/lucky-lotto-number-picker/id1591069274" target="_blank" rel="noreferrer" alt="com.lhmgapps.luckylottonumberpicker" title="com.lhmgapps.luckylottonumberpicker">
                    <img src="/images/mobile-stores--apple.png" alt="Lucky Lotto Number Picker in the App Store" title="Lucky Lotto Number Picker in the App Store" />
                </a>
            </div>

            <div className="feedback">
                <a alt="Leave Feedback" title="Leave Feedback" href="/feedback/">Got Feedback?</a>
            </div>

            <div className="translation-issues">
                <a alt="Translation Issues" title="Translations" href="/translations/">Translation Issues?</a>
            </div>

        </footer>
    )
}