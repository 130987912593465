//combine the reducers
import gamesReducer from "./gamesReducer";
import picksReducer from "./picksReducer";
import standardActionsReducer from "./standardActionsReducer";


import { combineReducers } from "redux";


const rootReducer = combineReducers({
    games: gamesReducer,
    picks: picksReducer,
    standard_actions: standardActionsReducer,
});


//if not using specific names
// const allReducers = combineReducers({
//     counterReducer,
//     userAuthenticatedReducer
// });


export default rootReducer;