



export function dates__format__US__LOCAL_TIME(date)
{
    if (!date)
    {
        return "";
    }


    var result = "";
    result = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();


    return result;
}



export function dates__format__YYYY_MM_DD__LOCAL_TIME(date)
{
    if (!date)
    {
        return "";
    }


    //var date = new Date();
    //getDate(), getMonth(), etc return local time
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var hh = date.getHours();
    var min = date.getMinutes();

    var yyyy = date.getFullYear();
    if (dd < 10)
    {
        dd = '0' + dd;
    }
    if (mm < 10)
    {
        mm = '0' + mm;
    }
    if (hh < 10)
    {
        hh = '0' + hh;
    }
    if (min < 10)
    {
        min = '0' + min;
    }

    var date_string = yyyy + "-" + mm + "-" + dd;
    return date_string;
}