import React from "react";
//import { Suspense } from "react";
import { Route, Switch } from 'react-router-dom';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';


//components
import Footer from './ui/components/Footer/Footer';
import Header from './ui/components/Header/Header';


//pages
import Feedback from './ui/pages/Feedback/Feedback';

import GamesHome from './ui/pages/Games/GamesHome/GamesHome';
import GamesDetails from './ui/pages/Games/GamesDetails/GamesDetails';
//import GamesDetails from './ui/pages/Games/GamesDetails/GamesDetails';
import GamesAnalyze from './ui/pages/Games/GamesAnalyze/GamesAnalyze';
import GamesRecentWinningNumbers from './ui/pages/Games/GamesRecentWinningNumbers/GamesRecentWinningNumbers';

import MyPicks from "./ui/pages/MyPicks/MyPicks";

import PurchaseTickets from "./ui/pages/PurchaseTickets/PurchaseTickets";

import Translations from './ui/pages/Translations/Translations';


//to pre-load images
import balls_primary from "./images/balls--primary-01.png";
import balls_secondary from "./images/balls--secondary-01.png";


//api
// import * as browser_functions from "./api/utilities/browser";
// import firebase_config from "./_config/firebase-config";


//const firebase = window.firebase;


class MainApp extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            site_ready: false,
            require_login: false,
        };
    }

    async componentDidMount()
    {
        this.setState(
            {
                site_ready: true,
            }
        );
    }

    componentDidCatch(error, errorInfo)
    {
        console.log("App.js:", "componentDidCatch", error);
    }

    render()
    {
        if (this.state.site_ready === false)
        {
            return (null)
        }


        return (
            <div className="main-app container">

                <Header />

                <div className="main-content">
                    <Switch>
                        <Route exact path="/Feedback" component={Feedback} />

                        <Route exact path="/Translations" component={Translations} />

                        <Route exact path="/games" component={GamesHome} />
                        <Route exact path="/games/:id/analyze/" component={GamesAnalyze} />
                        <Route exact path="/games/:id/recent-winning-numbers/" component={GamesRecentWinningNumbers} />
                        <Route exact path="/games/:id" component={GamesDetails} />

                        <Route exact path="/my-picks" component={MyPicks} />

                        <Route exact path="/purchase-tickets" component={PurchaseTickets} />
                    </Switch>
                </div>

                <Footer />


                <div style={{ display: "none" }}>
                    <img alt="test primary ball" src="/images/balls--primary-01.png" />
                    <img alt="test secondary ball" src="/images/balls--secondary-01.png" />
                    <img alt="test primary ball" src={balls_primary} />
                    <img alt="test secondary ball" src={balls_secondary} />
                </div>

            </div>
        )
    }
}

// const mapStateToProps = state => ({    
//     site_categories: state.categories.site_categories,
//     users_authentication: state.users_authentication
// });

export default MainApp;
//export default withRouter(App);
//export default withRouter(connect(mapStateToProps)(App));