
import { meta_set } from "../../../api/utilities/pages";
import "./styles/home.scss";

export default function Home() {
    meta_set({
        title: "Lucky Lotto Number Picker - #1 Place to Find Your Lucky Numbers",
        description: "Find your lucky lotto numbers today.",
        url_path: "/",
        image_url: "",
    });

    return (
        <div className="page-home">
            <div className="title">
                <a href="/"><img src="/images/text--title-01.png" alt="Lucky Lotto Number Picker" title="Lucky Lotto Number Picker" /></a>
            </div>

            <div className="logo">
                <img src="/images/logo512.png" alt="Lucky Lotto Number Picker Logo" title="Lucky Lotto Number Picker Logo" />
            </div>

            <div className="description">
                <h2>Predict Your Winning Lottery Numbers Today!</h2>
                <h3>Lucky Lotto Number Picker Can Help You Win Powerball, Mega Millions, NY Lotto, and more.</h3>
            </div>

            <div className="links">

                <a href="https://play.google.com/store/apps/details?id=com.lhmgapps.luckylottonumberpicker" target="_blank" rel="noreferrer" alt="com.lhmgapps.luckylottonumberpicker" title="com.lhmgapps.luckylottonumberpicker">
                    <img src="/images/mobile-stores--android.png" alt="Lucky Lotto Number Picker in the Google Play Store" title="Lucky Lotto Number Picker in the Google Play Store" />
                </a>

                <a href="https://apps.apple.com/us/app/lucky-lotto-number-picker/id1591069274" target="_blank" rel="noreferrer" alt="com.lhmgapps.luckylottonumberpicker" title="com.lhmgapps.luckylottonumberpicker">
                    <img src="/images/mobile-stores--apple.png" alt="Lucky Lotto Number Picker in the App Store" title="Lucky Lotto Number Picker in the App Store" />
                </a>

                <div>
                    <a href="/games/" alt="Play Lucky Lotto Number Picker Online" title="Play Lucky Lotto Number Picker Online">
                        <img src="/images/text--pick-online-01.png" alt="Play Lucky Lotto Number Picker Online" title="Play Lucky Lotto Number Picker Online" />
                    </a>
                </div>

                <div className="jackpocket">
                    <h3>Purchase Tickets at:</h3>
                    <a href="https://www.jackpocket.com/referrals/pmzd6n" target="_blank" alt="Play at JackPocket" title="Play at JackPocket">
                        <img src="/images/jackpocket.png" alt="Play at JackPocket" title="Play at JackPocket" />
                    </a>
                </div>

                <div className="feedback">
                    <a alt="Leave Feedback" title="Leave Feedback" href="/feedback/">Got Feedback?</a>
                </div>
                <div className="translation-issues">
                    <a alt="Translation Issues" title="Translations" href="/translations/">Translation Issues?</a>
                </div>
            </div>
        </div>
    );
}