import React, { Component } from "react";

import GamePicks from "../../../../../components/GamePicks/GamePicks";

import { get_random_numbers_by_game } from "../../../../../../api/utilities/from-app/games";
import { random__get_range_v1 } from "../../../../../../api/utilities/from-app/math";
import pick_methods from "../../../../../../api/utilities/from-app/enums/pick_methods.json";


export default class QuickPick extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            picks_made: 0,
            current_number: 0,
            current_line: "",
            first_line_picks: [],
            second_line_picks: [],
            first_line_numbers: [],
            second_line_numbers: [],
            game_over: false,

            completed_rounds: 0,
        };

        this.pick_method = pick_methods.quick_pick;


        //number of rounds
        this.number_of_rounds = 1;
        if (this.props.pick_details && this.props.pick_details.number_of_rounds)
        {
            this.number_of_rounds = this.props.pick_details.number_of_rounds;
            if (this.number_of_rounds <= 0)
            {
                this.number_of_rounds = 1;
            }
        }
        //console.log("this.number_of_rounds: " + this.number_of_rounds);


        //milliseconds
        this.delay_between_picks = 450;
        if (this.number_of_rounds >= 10)
        {
            this.delay_between_picks = 250;
        }
        if (this.number_of_rounds >= 50)
        {
            this.delay_between_picks = 50;
        }


        //this is state but we need to ensure its value is set immediately
        this.ready_for_next_pick = false;
        this.picks_saved = false;

        this.timer_tick = this.timer_tick.bind(this);

        this.start_picker = this.start_picker.bind(this)
        this.save_picks = this.save_picks.bind(this);
        this.make_pick = this.make_pick.bind(this);
    }


    ///////////////////////////////////////////////////////////// everything here is custom to this picker
    timer_tick()
    {
        //console.log("timer_tick: " + this.state.completed_rounds + " / " + this.number_of_rounds);

        if (this.state.game_over)
        {
            return;
        }


        this.make_pick();
    }


    after_make_pick()
    { }


    ///////////////////////////////////////////////////////////// everything below here is same across pickers
    componentDidMount()
    {
        this.start_picker();
    }


    componentWillUnmount()
    {
        //console.log("componentWillUnmount");
        if (this.timer)
        {
            //console.log("componentWillUnmount: clearTimeout");
            clearTimeout(this.timer);
        }
    }


    start_picker()
    {
        //get random numbers based on the game
        var rnd_numbers = get_random_numbers_by_game(this.props.game);

        //reset state  
        this.ready_for_next_pick = false;
        this.picks_saved = false;

        this.setState({
            picks_made: 0,
            current_number: 0,
            current_line: "",
            first_line_picks: [],
            second_line_picks: [],
            first_line_numbers: rnd_numbers.first_line_numbers,
            second_line_numbers: rnd_numbers.second_line_numbers,
            game_over: false,
            picks_saved: false,
        }, function ()
        {
            //starts the process
            this.timer = setInterval(this.timer_tick, this.delay_between_picks);
            this.ready_for_next_pick = true;
        });
    }


    make_pick()
    {
        //this code is the same for pick methods
        //console.log("make_pick");


        if (this.state.game_over)
        {
            //this is used to ensure additional picks are not made between game over and timer stopping
            //console.log("game over");
            return;
        }


        if (this.ready_for_next_pick === false)
        {
            console.log("timer_tick: NOT READY FOR NEXT PICK");
            return;
        }


        //console.log(this.state);
        this.ready_for_next_pick = false;


        let first_line_picks = this.state.first_line_picks;
        let second_line_picks = this.state.second_line_picks;

        let first_line_numbers = this.state.first_line_numbers;
        let second_line_numbers = this.state.second_line_numbers;
        // console.log("First line number: " + first_line_numbers.length);
        // console.log("Second line number: " + second_line_numbers.length);



        //first line picks
        if (this.state.first_line_picks.length < this.props.game.first_line.number_of_picks)
        {
            //get new first line pick
            //console.log("new first line pick");
            let rnd_index = random__get_range_v1(0, this.state.first_line_numbers.length - 1);
            first_line_picks.push(this.state.first_line_numbers[rnd_index]);

            if (this.props.game.first_line.unique_numbers)
            {
                //console.log("remove first line index: " + rnd_index);
                first_line_numbers.splice(rnd_index, 1);
            }
        }
        else if (this.state.second_line_picks.length < this.props.game.second_line.number_of_picks)
        {
            //get new second line pick
            //console.log("new second line pick");
            let rnd_index = random__get_range_v1(0, this.state.second_line_numbers.length - 1);
            second_line_picks.push(this.state.second_line_numbers[rnd_index]);

            if (this.props.game.second_line.unique_numbers)
            {
                //console.log("remove second line index: " + rnd_index);
                second_line_numbers.splice(rnd_index, 1);
            }
        }



        //game over:
        let game_over = false;
        if (first_line_picks.length >= this.props.game.first_line.number_of_picks
            && second_line_picks.length >= this.props.game.second_line.number_of_picks
        )
        {
            //game over
            //console.log("game over");
            game_over = true;
        }


        this.setState({
            game_over: game_over,
            first_line_picks: first_line_picks,
            second_line_picks: second_line_picks,
            first_line_numbers: first_line_numbers,
            second_line_numbers: second_line_numbers
        },
            function ()
            {
                //this game over is for the current round, not all rounds
                if (game_over)
                {
                    this.save_picks();
                }
                else
                {
                    this.ready_for_next_pick = true;
                }
            }
        );


        this.after_make_pick();
    }


    async save_picks()
    {
        //console.log("PlayQuickPick: save_picks");

        if (this.picks_saved)
        {
            console.log("picks already saved");
            return;
        }


        this.picks_saved = true;

        //console.log("stop timer");
        if (this.timer)
        {
            clearTimeout(this.timer);
        }


        // //sort the picks - dont currently like thie behavior               
        // var first_line_picks = this.state.first_line_picks;
        // first_line_picks.sort((a, b) => a < b ? -1 : 1);
        // // console.log(this.state.first_line_picks);
        // // console.log(first_line_picks);

        // var second_line_picks = this.state.second_line_picks;
        // second_line_picks.sort((a, b) => a < b ? -1 : 1);
        // // console.log(this.state.second_line_picks);
        // // console.log(second_line_picks);


        //are we done?
        var rounds_completed = true;
        var completed_rounds = this.state.completed_rounds;
        completed_rounds++;

        if (completed_rounds < this.number_of_rounds)
        {
            rounds_completed = false;
        }
        // console.log("Start new round? " + completed_rounds);
        //console.log("rounds_completed? " + rounds_completed);



        //save pick
        await this.props.onComplete(this.props.game, this.pick_method, this.state.first_line_picks, this.state.second_line_picks, rounds_completed);


        let self = this;
        this.setState({
            completed_rounds: completed_rounds
        }, function ()
        {
            if (rounds_completed === false)            
            {
                //console.log("Start a new round");
                //delay
                setTimeout(function () { self.start_picker(); }, 350);
            }
        })
    }


    render()
    {
        let round = this.state.completed_rounds + 1;
        round = this.state.completed_rounds;
        if (round > this.number_of_rounds)
        {
            round = this.number_of_rounds
        }


        return (
            <div style={{ marginTop: 40 }}>
                {this.number_of_rounds > 1 &&
                    <div className="completed-rounds">{round} / {this.number_of_rounds}</div>
                }

                <GamePicks game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
            </div>
        );
    }
}