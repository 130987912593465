



export function strings__clean(input)
{
    if (input === null || input === undefined)
    {
        input = "";
    }

    input = input.trim();


    return input;
}



export function get_url_title(input)
{
    if (!input)
    {
        return "";
    }


    input = input.toLowerCase();
    input= input.trim();

    // ":"
    while (input.indexOf(":") >= 0)
    {
        input = input.replace(":", "-");
    }

    // "/"
    while (input.indexOf("/") >= 0)
    {
        input = input.replace("/", "-");
    }

    // " "
    while (input.indexOf(" ") >= 0)
    {
        input = input.replace(" ", "-");
    }

    // "--"
    while (input.indexOf("--") >= 0)
    {
        input = input.replace("--", "-");
    }


    return input;
}






export function strings__is_string_empty(obj)
{
    if (obj === null || obj === undefined)
    {
        return true;
    }
    if (obj === "")
    {
        return true;
    }

    return false;
}


export function strings__replace(input, string_to_find, string_to_insert)
{
    if (strings__is_string_empty(input))
    {
        return input;
    }

    return input.split(string_to_find).join(string_to_insert);




    ////this doesnt work if the values are simliar (replace wtf with #wtf
    //while (input.indexOf(string_to_find) >= 0)
    //{
    //    input = input.replace(string_to_find, string_to_insert);
    //}

    //return input;
}



function strings__escape_html(input)
{
    if (input === null || input === "")
    {
        return input;
    }


    return input
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");


    //return $('<div/>').text(input).html();
}


export function strings__get_safe_html(body)
{
    if (body === null || body === undefined || body === "")
    {
        return "";
    }


    let html = body;
    html = strings__escape_html(html);
    html = html.replace(/(?:\r\n|\r|\n)/g, '<br />');


    return html;
}


export function strings__list_urls__unique(body)
{
    let unique_urls = [];
    let urls = strings__list_urls(body);

    for (let x = 0; x < urls.length; x++)
    {
        if (unique_urls.includes(urls[x]) === false)
        {
            unique_urls.push(urls[x]);
        }
    }

    // console.log("urls", urls);
    // console.log("unique_urls", unique_urls);

    return unique_urls;
}


export function strings__list_urls(body)
{
    if (body === null || body === undefined || body === "")
    {
        return [];
    }


    let urls = body.match(/(https?:\/\/)?([^.\s]+)?[^.\s]+\.[^\s]+/gi);
    if (urls === null || urls === undefined)
    {
        urls = [];
    }


    return urls;
}


export function strings__url_is_image(url)
{
    if (url === null || url === undefined || url === "")
    {
        return false;
    }


    if (url.endsWith(".jpg"))
    {
        return true;
    }


    return false;
}


export function strings__url_is_youtube_url(url)
{
    if (url === null || url === undefined || url === "")
    {
        return false;
    }

    
    var regex_pattern;
    var regex_results;


    //youtube                                                            
    //https://www.youtube.com/watch?v=rDs3o1uLEdU
    //https://youtu.be/rDs3o1uLEdU                    
    //^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+
    regex_pattern = /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+$/g;

    //regex_results = regex_pattern.exec(video_url_to_test);
    //console.log(regex_results);

    regex_results = url.match(regex_pattern);
    //console.log(regex_results);

    if (regex_results != null && regex_results.length > 0)
    {
        return true;
    }


    if (url.indexOf("https://m.youtube.com/watch") >= 0)
    {
        return true;
    }

    ///////////////!!!!
    //regex_pattern = /^(http\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
    //regex_results = regex_pattern.exec(video_url_to_test);
    //console.log(regex_results);

    //regex_results = video_url_to_test.match(regex_pattern);
    //console.log(regex_results);


    //regex_pattern = /^http:\/\/(?:www\.)?youtube.com\/watch\?(?=.*v=\w+)(?:\S+)?$/g
    //regex_results = regex_pattern.exec(video_url_to_test);
    //console.log(regex_results);

    //regex_results = video_url_to_test.match(regex_pattern);
    //console.log(regex_results);



    return false;
}



export function strings__convert_to_string(obj)
{
    //console.log("function__errors__convert_to_string");

    if (obj === null || obj === undefined)
    {
        return "";
    }

    //console.log(error);

    var str_value = "";
    if (typeof (obj) === "object")
    {
        str_value = JSON.stringify(obj);
        if (str_value === "{}")
        {
            str_value = obj.toString();
        }
    }
    else
    {
        str_value = obj;
    }

    // console.log("object string");
    // console.log(str_value);


    return str_value;
}


export function strings__shorten(input, max_length, suffix)
{
    //console.log(input);

    if (!input)
    {
        return "";
    }


    if (input.length <= max_length)    
    {
        return input;
    }


    input = input.substring(0, max_length);

    if (suffix)
    {
        input += suffix;
    }


    return input;
}


export function get_guid()
{
    return random__s4() + random__s4() +
        '-' + random__s4() + '-' + random__s4() +
        '-' + random__s4() + '-' + random__s4() +
        random__s4() + random__s4();
}


function random__s4()
{
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}