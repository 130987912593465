//import { clog } from "./misc";
//import { v4 as uuidv4 } from 'uuid'



export function random__new_math_random()
{
    //let stopwatch_start = new Date().getTime();

    //clog("random__new_math_random");
    //console.log(uuidv4());    

    //using unint8 since the range of values here doesn't have to be bigger than the max number in the array of available numbers
    let array_size = 50;
    array_size = 100;
    let rnd_values = [];
    rnd_values = crypto.getRandomValues(new Uint8Array(array_size));
    //rnd_values = crypto.getRandomValues(new Uint32Array(array_size));
    //console.log(rnd_values);


    //normalize results
    //https://stats.stackexchange.com/questions/70801/how-to-normalize-data-to-0-1-range
    let min_value = 100000000000000;
    let max_value = 0;
    let value_to_check = 0;

    for (let val of rnd_values)
    {
        //console.log(val);
        if (val < min_value)
        {
            min_value = val;
        }

        if (val > max_value)
        {
            max_value = val;
        }
    }
    //console.log("min max", min_value, max_value);    


    //if picking a random index, use the built in Math.random() to prevent endless loop
    let rnd_index = random__base_js_get_range(0, rnd_values.length - 1);
    //console.log("rnd_index", rnd_index);    


    value_to_check = rnd_values[rnd_index];
    //clog("value_to_check", value_to_check);


    let result = (value_to_check - min_value) / (max_value - min_value);
    //clog("random__new_math_random: result", result);


    //clog("random__new_math_random: total time: " + (new Date().getTime() - stopwatch_start));
    return result;
}


export function random__base_js_get_range(min_inclusive, max_inlcusive)
{
    //clog("random__base_js_get_range", min_inclusive, max_inlcusive);
    let range = max_inlcusive - min_inclusive;

    //add back the substracted (1-5 -> 5 -1 = 4 + 1 = 5)    
    range++;
    //clog("range", range);


    //0-1 - 0 inclusive, 1 exclusive
    let rnd = Math.random();
    //clog("rnd", rnd);


    //range: 10, rnd == .94545454, result is 945454
    rnd *= range;
    //clog("rnd * range", rnd);


    // //from 9, add 1 to go beyond 10
    // rnd += 1;
    // clog("rnd", rnd);


    //floor it to get whole number
    rnd = Math.floor(rnd);
    //clog("rnd Math.floor", rnd);


    //add min inclusive
    //if starts at 1, we need to move the results up 1
    rnd += min_inclusive;
    //clog("rnd += min_inclusive", rnd);


    //fake test
    if (rnd < min_inclusive || rnd > max_inlcusive)
    {
        console.log("ERRRRRRRRR: " + rnd);
    }



    //clog("rnd: FINAL: " + rnd);
    return rnd;
}


export function random__get_range_v1(min_inclusive, max_inlcusive)
{
    //clog("random__get_range_v1", min_inclusive, max_inlcusive);


    //get range
    let range = max_inlcusive - min_inclusive;

    //this is only needed if the max rnd does not return 1, in our case it does
    //so 5 - 1 = 4, if we get an rnd/math random of 1, we want to return index 4
    //if numbers are 1, 2, 3, 4, 5 => 5 - 1 = 4, we want a range of 5 so we add 1 
    //range++;
    //clog("range", range);


    //0-1 - 0 inclusive, 1 exclusive
    let rnd = random__new_math_random();
    //clog("rnd", rnd);


    //range: 10, rnd == .94545454, result is 945454
    rnd *= range;
    //clog("rnd * range", rnd);


    //floor it to get whole number
    rnd = Math.floor(rnd);
    //clog("rnd Math.floor", rnd);


    //add min inclusive
    //if starts at 1, we need to move the results up 1
    rnd += min_inclusive;
    //clog("rnd += min_inclusive", rnd);


    //fake test
    if (rnd < min_inclusive || rnd > max_inlcusive)
    {
        console.log("ERRRRRRRRR: " + rnd);
        throw "stop";
    }
    // if (rnd === min_inclusive)
    // {
    //     throw "min";
    // }
    // else if (rnd === max_inlcusive)
    // {
    //     throw "max";
    // }




    //clog("rnd: FINAL: " + rnd);
    //throw "stop";


    return rnd;
}