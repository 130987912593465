import React, { Component } from "react";

//import PickResults from "../../../../../components/PickResults/PickResults";

import { get__timers__human_capable_tick_in_milliseconds } from "../../../../../../api/globals";
import { get_random_numbers_by_game } from "../../../../../../api/utilities/from-app/games";
import { random__get_range_v1, random__base_js_get_range } from "../../../../../../api/utilities/from-app/math";
import pick_methods from "../../../../../../api/utilities/from-app/enums/pick_methods.json";


const timer_interval__animation = 50;
//console.log(pick_methods);


export default class TapPickInfinite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picks_made: 0,
            current_number: 0,
            current_line: "first",
            first_line_numbers: [],
            second_line_numbers: [],
            animation_number: 0,
            game_over: false,
            show_clicked_state: false,
        };


        this.pick_method = pick_methods.tap_infinite;
        this.first_line_picks_this_round = 0;
        this.second_line_picks_this_round = 0;


        //milliseconds
        //console.log("construct");
        this.delay_between_picks = get__timers__human_capable_tick_in_milliseconds();


        //this is "state" but we need to ensure its value is set immediately
        this.ready_for_next_pick = false;
        this.picks_saved = false;


        this.timer = null;
        this.timer_ball_animation = null;
    }


    ///////////////////////////////////////////////////////////// everything here is custom to this picker    
    pick_random_number = () => {
        //console.log("pick_random_number");


        if (this.state.game_over) {
            return;
        }


        let rnd_index = 0;
        let new_number = 0;

        if (this.state.current_line === "first") {
            rnd_index = random__get_range_v1(0, this.state.first_line_numbers.length - 1);
            new_number = this.state.first_line_numbers[rnd_index];
        }
        else if (this.state.current_line === "second") {
            rnd_index = random__get_range_v1(0, this.state.second_line_numbers.length - 1);
            new_number = this.state.second_line_numbers[rnd_index];
        }


        if (new_number === 0) {
            console.log("ZERO not valid number.");
            return;
        }
        //console.log("new_number: " + new_number);


        this.setState({
            current_number: new_number,
        });
    }


    show_random_number = () => {
        //console.log("show_random_number");


        if (this.state.game_over) {
            return;
        }

        //use base random range, it is quicker and unrelated to actually picking the number
        let rnd_index = 0;
        let new_number = 0;

        if (this.state.current_line === "first") {
            rnd_index = random__base_js_get_range(0, this.state.first_line_numbers.length - 1);
            new_number = this.state.first_line_numbers[rnd_index];
        }
        else if (this.state.current_line === "second") {
            rnd_index = random__base_js_get_range(0, this.state.second_line_numbers.length - 1);
            new_number = this.state.second_line_numbers[rnd_index];
        }
        //console.log(new_number);


        this.setState({
            animation_number: new_number,
        });
    }


    ///////////////////////////////////////////////////////////// everything below here is same across pickers    
    componentDidMount() {
        this.start_picker();
    }


    componentWillUnmount() {
        //console.log("componentWillUnmount");
        if (this.timer) {
            //console.log("componentWillUnmount: clearTimeout");
            clearTimeout(this.timer);
        }

        if (this.timer_ball_animation) {
            clearTimeout(this.timer_ball_animation);
        }
    }


    start_picker = () => {
        // console.log("start picker");
        // console.log(this.props.game);

        this.ready_for_next_pick = false;
        this.picks_saved = false;

        let number_of_rnd_rounds = 0;
        let first_line_numbers = [];
        let second_line_numbers = [];


        //get "lots" of random numbers

        //first line
        number_of_rnd_rounds = this.props.game.first_line.range_end;
        number_of_rnd_rounds = 10;
        for (let x = 1; x <= number_of_rnd_rounds; x++) {
            let rnd_numbers = get_random_numbers_by_game(this.props.game);
            first_line_numbers = [...first_line_numbers, ...rnd_numbers.first_line_numbers];
        }
        // console.log(first_line_numbers);
        // console.log("");


        if (this.props.game.second_line.number_of_picks > 0) {
            //second line
            number_of_rnd_rounds = this.props.game.second_line.range_end;
            number_of_rnd_rounds = 10;

            for (let x = 1; x <= number_of_rnd_rounds; x++) {
                let rnd_numbers = get_random_numbers_by_game(this.props.game);
                second_line_numbers = [...second_line_numbers, ...rnd_numbers.second_line_numbers];
            }
        }
        //console.log(second_line_numbers);


        this.setState({
            picks_made: 0,
            current_number: 0,
            first_line_numbers: first_line_numbers,
            second_line_numbers: second_line_numbers,
            animation_number: "",
            game_over: false,
            picks_saved: false,
        }, function () {
            //starts the process
            this.timer = setInterval(this.pick_random_number, this.delay_between_picks);
            this.timer_ball_animation = setInterval(this.show_random_number, timer_interval__animation);
            this.ready_for_next_pick = true;
        });
    }


    make_pick = async () => {
        //this code is the same for pick methods
        //console.log("make_pick");
        //console.log("this.ready_for_next_pick", this.ready_for_next_pick);
        //console.log("this.state.current_number", this.state.current_number);

        if (this.ready_for_next_pick === false) {
            console.log("this.ready_for_next_pick = false;");
            return;
        }

        if (this.state.current_number <= 0) {
            console.log("Invalid number.");
            return;
        }


        this.setState({
            show_clicked_state: true,
        });


        this.ready_for_next_pick = false;
        await this.save_pick(this.state.current_line, this.state.current_number);
        this.picks_made++;


        let next_current_line = this.state.current_line;

        if (this.state.current_line === "first") {
            this.first_line_picks_this_round++;
            if (this.first_line_picks_this_round === this.props.game.first_line.number_of_picks) {
                if (this.props.game.second_line.number_of_picks > 0) {
                    console.log("change to second line");

                    this.first_line_picks_this_round = 0;
                    next_current_line = "second";
                }
            }
        }
        else if (this.state.current_line === "second") {
            this.second_line_picks_this_round++;
            if (this.second_line_picks_this_round === this.props.game.second_line.number_of_picks) {
                console.log("change to first line");

                this.second_line_picks_this_round = 0;
                next_current_line = "first";
            }
        }


        if (this.state.current_line !== next_current_line) {
            this.setState({
                current_line: next_current_line
            }, function () {
                this.ready_for_next_pick = true;
            });
        }
        else {
            this.ready_for_next_pick = true;

            setTimeout(() => {
                //wait to show normal background
                this.setState({
                    show_clicked_state: false,
                });
            }, 100)
        }
    }


    save_pick = async (line, number) => {
        //console.log("save_pick", line, number);


        //save pick
        let first_line = [];
        let second_line = [];

        if (this.state.current_line === "first") {
            first_line = [this.state.current_number];
        }
        else if (this.state.current_line === "second") {
            second_line = [this.state.current_number];
        }

        await this.props.onComplete(this.props.game, this.pick_method, first_line, second_line, false);
    }


    render() {
        //console.log(this.state);

        return (
            <div className="pick-method-tap">
                {this.state.game_over === false &&
                    <div className="tap-wrapper" onClick={() => this.make_pick()} data-clicked-state={this.state.show_clicked_state}>

                        <div className="balls">
                            <div className="non-ball"></div>
                            <div className="non-ball"></div>

                            {this.state.current_line === "first" &&
                                <div className="ball" alt="Primary Pick" title="Primary Pick" style={{ backgroundImage: "url('/images/balls--primary-01.png')" }} >
                                    <span>{this.state.current_number}</span>
                                </div>
                            }
                            {this.state.current_line === "second" &&
                                <div className="ball" alt="Secondary Pick" title="Secondary Pick" style={{ backgroundImage: "url('/images/balls--secondary-01.png')" }} >
                                    <span>{this.state.current_number}</span>
                                </div>
                            }

                            <div className="non-ball"></div>
                            <div className="non-ball"></div>
                        </div>

                        <div className="tap-to-pick">TAP/CLICK IN BOX TO PICK</div>

                    </div>
                }

                {this.state.game_over === true &&
                    <div>
                        <div>Picks Saved</div>
                    </div>
                }

            </div>
        );
    }
}