import { get_pick_method_name } from "./from-app/games";
import { get_default_pick } from "./from-app/entities/pick";
import { get_default_server_pick } from "./from-app/entities/server-pick";
import { local_storage__get_object, local_storage__set_object, get_session_id } from "./browser";
import cache_keys from "../cache-keys";
import * as globals from "../globals";

export function list_saved_picks() {
    var saved_picks = local_storage__get_object(cache_keys.local_storage__picks);
    if (!saved_picks) {
        saved_picks = [];
    }


    return saved_picks;
}



export async function load_saved_data_by_game_id(game_id) {
    let saved_picks = list_saved_picks();
    let game_picks = [];

    for (let saved_pick of saved_picks) {
        if (saved_pick.game_id === game_id) {
            game_picks.push(saved_pick);
        }
    }


    return game_picks;
}


export function data__create_pick_data(game, pick_method, first_line_picks, second_line_picks) {
    let new_pick_data = get_default_pick();
    new_pick_data.ts = new Date().getTime();
    new_pick_data.game_name = game.name;
    new_pick_data.game_id = game.id;
    new_pick_data.pick_method = pick_method;
    new_pick_data.first_line = first_line_picks;
    new_pick_data.second_line = second_line_picks;
    //console.log("new_pick_data", new_pick_data);


    return new_pick_data;
}



export async function data__save_pick_locally(pick_data) {
    //console.log("data__save_pick_locally", pick_data);

    var saved_picks = local_storage__get_object(cache_keys.local_storage__picks);
    if (!saved_picks) {
        saved_picks = [];
    }

    saved_picks.push(pick_data);
    //console.log(saved_picks);


    //1 year
    let expiration_in_seconds = 31536000;
    local_storage__set_object(cache_keys.local_storage__picks, saved_picks, expiration_in_seconds);
}



export async function data__delete_all_picks() {
    //console.log("data__save_pick_locally", pick_data);

    var saved_picks = [];

    //1 year
    let expiration_in_seconds = 31536000;
    local_storage__set_object(cache_keys.local_storage__picks, saved_picks, expiration_in_seconds);
}


export async function data__delete_game_picks(game) {
    //console.log("data__save_pick_locally", pick_data);

    var saved_picks = local_storage__get_object(cache_keys.local_storage__picks);
    if (!saved_picks) {
        saved_picks = [];
    }

    saved_picks = saved_picks.filter(x => x.game_id !== game.id);


    //1 year
    let expiration_in_seconds = 31536000;
    local_storage__set_object(cache_keys.local_storage__picks, saved_picks, expiration_in_seconds);
}



export async function data__save_pick_to_server(pick_data) {
    //console.log("data__save_pick_to_server");
    //console.log("data__save_pick_to_server", pick_data);
    // console.log(window);
    // console.log(window.location);    


    try {
        var url = "https://ay4r8q0ypi.execute-api.us-east-1.amazonaws.com/SavePick";

        let new_sever_pick = get_default_server_pick();
        new_sever_pick.pick = pick_data;
        new_sever_pick.platform = "Web";
        new_sever_pick.game_type = pick_data.game_name;
        new_sever_pick.game_name = pick_data.game_name;
        new_sever_pick.game_id = pick_data.game_id;
        new_sever_pick.device_id = get_session_id();
        new_sever_pick.is_test_device = false;
        if (window.location.host.indexOf("localhost") >= 0) {
            new_sever_pick.is_test_device = true;
        }
        new_sever_pick.is_dev = new_sever_pick.is_test_device;

        new_sever_pick.pick_method = get_pick_method_name(pick_data.pick_method);
        new_sever_pick.pick_method_id = pick_data.pick_method;
        new_sever_pick.timers__human_capable_tick_in_milliseconds = globals.get__timers__human_capable_tick_in_milliseconds();



        if (window.location.host.indexOf("localhost") >= 0) {
            console.log("DSPTS");
            console.log(new_sever_pick);
            return;
            console.log("WARNING!!!! SAVING IN DEV");
        }


        // console.log("SENDING TO SERVER");
        // console.log("SENDING TO SERVER", new_sever_pick);


        //let res = await fetch(url, {
        await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.            
            body: JSON.stringify(new_sever_pick),
        });
        //console.log(res);
    }
    catch (ex) {
        console.log("save_game_data: unhandled exception");
        console.log(ex);
    }
}