const cache_keys = {

    local_storage__games__selected_tab: "games.selected_tab",

    local_storage__games__favorites: "games.favorites",
    local_storage__picks: "picks",

    local_storage__session_id: "session_id",
}

export default cache_keys;