import React from "react";

//components
import GameItem from "../components/GameItem/GameItem";

//api
import GamesList from "../../../../api/json/games.json";
import { local_storage__get_object, local_storage__set_object } from "../../../../api/utilities/browser";
import cache_keys from "../../../../api/cache-keys";
import countries_list from "../../../../api/json/countries.json"
import us_states_list from "../../../../api/json/us-states.json"

import "./../styles/games.scss";
import { meta_set } from "../../../../api/utilities/pages";



//console.log(GamesList);
// console.log(countries);
// console.log(us_states);


class GamesHome extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            selected_tab: "usa",
            sections: [],
            all_games: [],
            games: [],
            all_favorites: [],
            favorites: [],
            filter: "",
        };
    }


    async componentDidMount()
    {
        meta_set({
            title: "Pick Your Favorite Lotto Game",
            description: "Find your lucky lotto numbers today.",
            url_path: "/games/",
            image_url: "",
        });

        let all_games = [];
        let all_favorites = [];
        let sections = [];

        //console.log(GamesList);
        //get games and sections
        for (let game of GamesList)
        {
            //console.log(game);
            all_games.push(game);

            if (sections.includes(game.tab_section) === false)
            {
                sections.push(game.tab_section);
            }
        }
        //console.log(sections);


        //get favorites
        var storage_favorites = local_storage__get_object(cache_keys.local_storage__games__favorites);
        //console.log(storage_favorites);
        if (storage_favorites)
        {
            all_favorites = storage_favorites;
        }



        this.setState(
            {
                sections: sections,
                all_games: all_games,
                all_favorites: all_favorites,
            }, function () { this.load_last_tab(); }
        );

        // try
        // {
        //     var rect = element.getBoundingClientRect();
        //     console.log(rect.top, rect.right, rect.bottom, rect.left);
        //     window.scrollTo(0, 0);
        // }
        // catch { }
    }


    componentDidUpdate(previous_props, previous_state)
    {
        //console.log(previous_props, previous_state);
    }


    onChange = (e) =>
    {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({ [e.target.name]: value });
    }


    load_last_tab = async () =>
    {
        let selected_tab = local_storage__get_object(cache_keys.local_storage__games__selected_tab);
        //console.log("selected_tab: " + selected_tab);
        if (!selected_tab)
        {
            selected_tab = "usa";
        }

        this.load_tab(selected_tab);
    }


    load_tab = (tab) =>
    {
        //console.log("load_tab: " + tab);

        if (!tab)
        {
            tab = "usa";
        }
        tab = tab.toLowerCase();


        //get games
        let games = [];
        if (tab === "all" || tab === "favorites")
        {
            games = this.state.all_games.filter(x => x.id > 0);
        }
        else
        {
            games = this.state.all_games.filter(x => x.tab_section.toLowerCase() === tab);
        }


        //sort games
        games.sort((a, b) => a.name < b.name ? -1 : 1);
        //console.log(games);



        //get favorites
        let favorites = [];
        for (let game of games)
        {
            for (let saved_favorite of this.state.all_favorites)
            {
                if (game.id === saved_favorite)
                {
                    favorites.push(game);
                }
            }
        }
        // console.log("favorites");
        // console.log(favorites);


        this.setState({
            games: games,
            favorites: favorites,
            selected_tab: tab
        });

        //save selected tab in storage
        let expiration_in_seconds = 60 * 60 * 24 * 365;
        //console.log(expiration_in_seconds);
        local_storage__set_object(cache_keys.local_storage__games__selected_tab, tab, expiration_in_seconds);
    }


    onClick_tab = (tab) =>
    {
        //console.log("onClick_tab: " + tab);
        this.load_tab(tab);
    }


    onClick_toggle_favorite = (game) =>
    {
        //console.log("onClick_toggle_favorite", game);

        var storage_favorites = local_storage__get_object(cache_keys.local_storage__games__favorites);
        if (!storage_favorites)
        {
            storage_favorites = [];
        }
        //console.log(storage_favorites);


        let favorite_exists = storage_favorites.includes(game.id);
        if (favorite_exists)
        {
            //console.log("remove favorites");
            storage_favorites = storage_favorites.filter(x => x !== game.id);
        }
        else
        {
            //console.log("add favorites");
            storage_favorites.push(game.id);
        }


        //save to storage
        //1 year
        let expiration_in_seconds = 31536000;
        local_storage__set_object(cache_keys.local_storage__games__favorites, storage_favorites, expiration_in_seconds);


        //update state and reload tab        
        this.setState({
            all_favorites: storage_favorites
        },
            function () { this.load_tab(this.state.selected_tab); }
        );
    }


    render()
    {
        //console.log(this.state);


        let games = this.state.games;
        let favorites = this.state.favorites;

        if (this.state.filter)
        {
            games = games.filter(x => x.name.toLowerCase().includes(this.state.filter.toLowerCase()));
            favorites = favorites.filter(x => x.name.toLowerCase().includes(this.state.filter.toLowerCase()));
        }


        let non_state_games = games.filter(x => x.state === "");
        let non_country_games = games.filter(x => x.country === "");
        let state_games = games.filter(x => x.state !== "");
        let country_games = games.filter(x => x.country !== "");
        let final_games = [...non_state_games, ...state_games];

        let section_items = [];
        let section_values = [];



        if (this.state.selected_tab === "usa")
        {
            let unlabeled_section_item = {
                value: "-",
                label: "",
                data: [],
            };
            for (let game of non_state_games)
            {
                //console.log(game);
                unlabeled_section_item.data.push(game);
            }
            section_items.push(unlabeled_section_item);
            //console.log("section_items", section_items);


            for (let game of state_games)
            {
                if (game.state && section_values.indexOf(game.state) < 0)
                {
                    section_values.push(game.state);
                }
            }

            section_values.sort((a, b) => a < b ? -1 : 1);

            for (let section_value of section_values)
            {
                var state_details = us_states_list.find(x => x.abbreviation === section_value);
                let section_item = {
                    value: section_value,
                    label: state_details.name,
                    data: [],
                };
                for (let game of final_games.filter(x => x.state === section_value))
                {
                    section_item.data.push(game);
                }

                section_items.push(section_item);
            }
        }
        else
        {
            let unlabeled_section_item = {
                value: "-",
                label: "",
                data: [],
            };
            for (let game of non_country_games)
            {
                //console.log(game);
                unlabeled_section_item.data.push(game);
            }
            section_items.push(unlabeled_section_item);
            //console.log("section_items", section_items);


            for (let game of country_games)
            {
                if (game.country && section_values.indexOf(game.country) < 0)
                {
                    section_values.push(game.country);
                }

            }

            section_values.sort((a, b) => a < b ? -1 : 1);


            for (let section_value of section_values)
            {
                var country_details = countries_list.find(x => x.code === section_value);
                let section_item = {
                    value: section_value,
                    label: country_details.name,
                    data: [],
                };
                for (let game of final_games.filter(x => x.country === section_value))
                {
                    section_item.data.push(game);
                }

                section_items.push(section_item);
            }
        }
        // console.log("section_values", section_values);
        // console.log("section_items", section_items);


        return (
            <div className="page-play games-all-games" >
                <h1 className="page-h1">Games</h1>

                <div className="search-games">
                    <input type="text" className="form-control" placeholder="search games" name="filter" onChange={this.onChange} />
                </div>

                <div className="game-sections">
                    <button className="favorites" data-selected={this.state.selected_tab === "favorites"} alt="Favorites" title="Favorites" type="button" onClick={() => this.onClick_tab("favorites")}><i className="fas fa-star"></i></button>
                    {this.state.sections.map((section) =>
                    {
                        //console.log(game);
                        let selected = false;
                        if (this.state.selected_tab.toLowerCase() === section.toLowerCase())
                        {
                            selected = true;
                        }

                        return (
                            <button data-selected={selected} alt={section} title={section} key={section} type="button" onClick={() => this.onClick_tab(section)}>{section}</button>
                        )
                    })
                    }
                </div>

                {this.state.selected_tab === "favorites" && favorites.length > 0 &&
                    <div className="games-list games-favorites-list">
                        {favorites.map((game) =>
                        {
                            return (
                                <GameItem key={game.id} game={game} all_favorites={this.state.all_favorites} onClick_toggle_favorite={this.onClick_toggle_favorite} />
                            )
                        })
                        }
                    </div>
                }

                {this.state.selected_tab !== "favorites" &&
                    <div className="games-list">
                        {section_items.map((section_item) => 
                        {
                            //console.log("section_item.value", section_item.value)
                            return (
                                <div key={section_item.value}>
                                    <div key={section_item.value} className="section-title">{section_item.label}</div>
                                    {section_item.data.map((game) =>
                                    {
                                        return (
                                            <GameItem key={game.id} game={game} all_favorites={this.state.all_favorites} onClick_toggle_favorite={this.onClick_toggle_favorite} />
                                        )
                                    })}
                                </div>
                            )
                        }
                        )}
                    </div>
                }

            </div >
        );
    }
}
export default GamesHome