import React from "react";
import { Link } from "react-router-dom";

//redux related
import { connect } from "react-redux";
import { picks__list } from "../../../redux/actions/picksActions";

import GamePicks from "../../components/GamePicks/GamePicks";

import { get_url_title } from "../../../api/utilities/strings";
import { list_saved_picks, data__delete_all_picks } from "../../../api/utilities/data";
import { get_game_by_id, get_pick_method_name } from "../../../api/utilities/from-app/games";
import { get_game_analysis } from "../../../api/utilities/from-app/analyze";

//console.log(GamesList);

import "./styles/my-picks.scss";
import { meta_set } from "../../../api/utilities/pages";


class MyPicks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            view: "", //text or default
            text_output: "",
        };
    }


    async componentDidMount() {
        this.load_data();

        meta_set({
            title: "Your Top Lotto Picks",
            description: "View your top lotto picks.",
            url_path: "/my-picks/",
            image_url: "",
        });
    }


    load_data = async () => {
        let saved_picks = list_saved_picks();

        let unique_game_ids = [];
        for (let saved_pick of saved_picks) {
            if (unique_game_ids.includes(saved_pick.game_id) === false) {
                unique_game_ids.push(saved_pick.game_id);
            }
        }


        let games = [];
        for (let game_id of unique_game_ids) {
            let game = get_game_by_id(game_id);
            if (game) {
                games.push(game);
            }
        }
        //console.log(games);


        for (let game of games) {
            game.top_picks = await get_game_analysis(game);
            //console.log(game.top_picks);
        }
        //console.log(games);


        this.setState({
            games: games,
            view: "",
        });
    }


    onClick_toggle_view = () => {
        if (this.state.view === "") {
            let text_output = this.state.text_output;
            if (text_output === "") {
                try {
                    for (let game of this.state.games) {
                        text_output += "-------------------------------------";
                        text_output += "\r\n";
                        text_output += game.name;
                        text_output += "\r\n";
                        text_output += "-------------------------------------";
                        text_output += "\r\n";
                        text_output += "\r\n";

                        for (let pick of game.top_picks) {
                            //console.log(pick);

                            if (pick.first_line.length === 0) {
                                continue;
                            }

                            let pick_method = get_pick_method_name(pick.pick_method);
                            if (!pick_method) {
                                //consoe.log("pick method not found");
                                return null;
                            }


                            //pick method
                            text_output += pick_method + " (" + pick.number_of_picks + ")";
                            text_output += "\r\n";

                            if (pick.first_line.length > 0) {
                                text_output += pick.first_line.join(", ");
                            }
                            if (pick.second_line.length > 0) {
                                text_output += "\r\n";
                                text_output += pick.second_line.join(", ");
                            }

                            text_output += "\r\n";
                            text_output += "\r\n";

                            //is this the last one
                            if (pick.pick_method !== game.top_picks[game.top_picks.length - 1].pick_method) {
                                text_output += "--";
                                text_output += "\r\n";
                            }
                            text_output += "\r\n";
                        }


                        // if (picks_by_game < game.top_picks.length - 1)
                        // {
                        //     text_output += "\r\n";
                        //     text_output += "\r\n";
                        // }
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }


            this.setState({
                view: "text_view",
                text_output: text_output,
            });
        }
        else {
            this.setState({
                view: "",
            });
        }
    }


    onClick_delete_all_picks = () => {
        if (window.confirm("Are you sure? This cannot be undone.")) {
            //console.log("confirmed");

            //delete picks
            data__delete_all_picks();

            //update redux - only doing this for the header            
            try {
                this.props.dispatch(picks__list());
            }
            catch (err) {
                console.error(err);
            }

            //reload data (redundant from above?)
            this.load_data();
        }
    }


    render() {
        //console.log(this.state);        

        return (
            <div className="page-my-picks" >
                <h1>My Picks</h1>

                <div className="top-functions">
                    <button className="toggle" type="button" onClick={() => this.onClick_toggle_view()}>Toggle Text View</button>
                    <button className="delete" type="button" onClick={() => this.onClick_delete_all_picks()}>Delete All Picks</button>
                </div>

                {this.state.view === "text_view" &&
                    <div className="winning-numbers-text-view">
                        <textarea value={this.state.text_output} onChange={() => { }}></textarea>
                    </div>
                }


                {this.state.view === "" && this.state.games.length === 0 &&
                    <div className="winning-numbers-empty">
                        You haven't made any picks yet. Click <Link to="/games/" alt="Pick a Game" title="Pick a Game">"Games"</Link> to start making picks.
                    </div>
                }

                {this.state.view === "" &&
                    <div className="winning-numbers-list">
                        {this.state.games.map((game) => {
                            //console.log(game);
                            let link_url = "/games/" + get_url_title(game.name) + "/";
                            //console.log(link_url);

                            return (
                                <div key={game.id} className="winning-numbers-item">
                                    <h2 title={game.name} alt={game.name} style={{ textAlign: "center", marginBottom: 20, }}><Link style={{ fontSize: "3rem", fontFamily: "Lobster", }} title={game.name} alt={game.name} to={link_url}>{game.name}</Link></h2>

                                    {game.top_picks.map(top_pick => {
                                        if (top_pick.pick_method ===  null ) {
                                            return null;
                                        }

                                        return (
                                            <div key={top_pick.pick_method} style={{ marginBottom: 30 }}>
                                                <h3 style={{ textAlign: "center", marginBottom: 20 }}>{get_pick_method_name(top_pick.pick_method)}</h3>
                                                <GamePicks game={game} first_line_picks={top_pick.first_line} second_line_picks={top_pick.second_line} />
                                            </div>
                                        )
                                    })}

                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div >
        );
    }
}
//export default MyPicks
//these are the state properties you want access to
//these will get added to this.props
const mapStateToProps = state => ({
    picks: state.picks.items
});


export default connect(mapStateToProps)(MyPicks);