
import "./age-disclaimer.scss";

export default function AgeDisclaimer()
{
    return (
        <div className="age-disclaimer row">
            <div className="col-4">
                Age limit (18+)
            </div>
            <div className="col-4">
                Please play responsibly.
            </div>
            <div className="col-4">
                <a href="http://www.gamcare.org.uk/" target="_blank" rel="noreferrer">GamCare</a>
            </div>
        </div>
    )
}