import React, { Component } from "react";

//import PickResults from "../../../../../components/PickResults/PickResults";
import GamePicks from "../../../../../components/GamePicks/GamePicks";

import { get__timers__human_capable_tick_in_milliseconds } from "../../../../../../api/globals";
import { get_random_picks } from "../../../../../../api/utilities/from-app/games";
import pick_methods from "../../../../../../api/utilities/from-app/enums/pick_methods.json";

import "./release-to-pick.scss";


export default class ReleaseToPickAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_line_picks: [],
            second_line_picks: [],
            game_over: false,
            game_playing: false,
            first_pick_created: false,
        };


        this.pick_method = pick_methods.release_to_pick_all;


        //milliseconds
        this.delay_between_picks = get__timers__human_capable_tick_in_milliseconds();


        this.pick_made = false;
        this.picks_saved = false;


        this.timer = null;
        this.timer_tick = this.timer_tick.bind(this);
    }


    ///////////////////////////////////////////////////////////// everything here is custom to this picker
    timer_tick() {
        //console.log("timer_tick");

        if (this.state.game_over) {
            return;
        }


        this.get_random_numbers();
    }


    get_random_numbers = () => {
        //get rnd picks
        var rnd_picks = get_random_picks(this.props.game);
        //console.log(rnd_picks);

        this.setState({
            first_line_picks: rnd_picks.first_line_picks,
            second_line_picks: rnd_picks.second_line_picks,
            first_pick_created: true,
        });
    }


    ///////////////////////////////////////////////////////////// everything below here is same across pickers    
    componentDidMount() {
        //console.log(this.props.game);        
    }


    componentWillUnmount() {
        //console.log("componentWillUnmount");
        if (this.timer) {
            //console.log("componentWillUnmount: clearTimeout");
            clearTimeout(this.timer);
        }
    }


    toggle__start_picker__make_pick = () => {

        // console.log("toggle__start_picker__make_pick");
        // console.log(this.state);

        if (this.state.game_playing === false) {
            //console.log("toggle__start_picker__make_pick: start_picker");
            this.start_picker();
        }
        else {
            //console.log("toggle__start_picker__make_pick: make_pick");
            this.make_pick();
        }
    }


    start_picker = () => {
        //console.log("start_picker");

        if (this.state.game_playing) {
            console.log("game already playing");
            return;
        }


        //reset state  
        this.ready_for_next_pick = false;
        this.picks_saved = false;
        this.pick_made = false;


        this.setState({
            first_line_picks: [],
            second_line_picks: [],
            game_playing: true,
            game_not_started: false,
            game_over: false,
            first_pick_created: false,
        }, function () {
            if (
                this.props.preferences__vibrate === true
            ) {
                try {
                    //console.log("vibrate");
                    //Vibration.vibrate(100)
                }
                catch (err) {
                    console.log(err);
                }
            }

            //starts the process
            this.timer = setInterval(this.timer_tick, this.delay_between_picks);
        });
    }


    make_pick = () => {
        //this code is the same for pick methods
        //console.log("make_pick");


        if (this.state.first_pick_created === false) {
            //console.log("first pick not created yet");

            //stop timer
            clearTimeout(this.timer);

            this.setState({
                first_line_picks: [],
                second_line_picks: [],
                game_playing: false,
                game_not_started: true,
                game_over: false,
                first_pick_created: false,
            });


            return;
        }


        //get picks
        let first_line_picks = this.state.first_line_picks;
        let second_line_picks = this.state.second_line_picks;


        //stop timer
        clearTimeout(this.timer);


        if (this.pick_made) {
            console.log("pick already made");
            return;
        }
        this.pick_made = true;


        if (this.state.game_over) {
            //this is used to ensure additional picks are not made between game over and timer stopping
            console.log("game over already");
            return;
        }



        this.setState({
            game_over: true,
            first_line_picks: first_line_picks,
            second_line_picks: second_line_picks,
        },
            function () {
                this.save_picks();
                // if (this.state.first_pick_created)
                // {
                //     this.save_picks();
                // }
                // else
                // {
                //     console.log("too quick");
                //}
            }
        );
    }


    save_picks = async () => {
        //console.log("save_picks");


        if (this.picks_saved) {
            console.log("picks already saved");
            return;
        }
        this.picks_saved = true;


        //save pick
        let rounds_completed = true;
        await this.props.onComplete(this.props.game, this.pick_method, this.state.first_line_picks, this.state.second_line_picks, rounds_completed);
    }


    render() {
        // console.log(this.state);
        //console.log(this.state.first_line_picks);
        // console.log(this.state.second_line_picks);

        //<PickResults game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
        //<div onClick={}={this.start_picker} onPressOut={this.make_pick}>

        return (
            <div className="pick-method-release-to-pick">
                {this.state.game_over === false &&
                    <>
                        <div onClick={this.toggle__start_picker__make_pick}>

                            <div style={{ margin: 10, borderRadius: 10, overflow: "hidden", borderWidth: 5, borderColor: "#333", backgroundColor: "rgba(50, 50, 50, .5)" }}>

                                <div className="wrapper">
                                    <div style={{ flex: 1, marginTop: 20 }}>
                                        <GamePicks game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
                                    </div>
                                </div>

                                {this.state.game_playing === false &&
                                    <div className="tap">
                                        <div>CLICK TO START</div>
                                    </div>
                                }

                                {this.state.game_playing === true &&
                                    <div className="tap">
                                        <div>CLICK AGAIN TO PICK</div>
                                    </div>
                                }

                            </div>

                        </div>
                    </>
                }

                {this.state.game_over === true &&
                    <div>
                        <GamePicks game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
                    </div>
                }
            </div>
        );
    }
}