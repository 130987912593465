import React from "react"

import "./game-picks.scss";


export default function GamePicks(props)
{
    //console.log("GamePicks", props);
    if (!props.game)
    {
        return (null);
    }



    let max_numbers_per_row = 6;
    if (props.game.first_line.number_of_picks === 10)
    {
        max_numbers_per_row = 5;
    }

    let number_pick_width_percent = 1 / props.game.first_line.number_of_picks;
    if (props.game.first_line.number_of_picks > max_numbers_per_row)
    {
        number_pick_width_percent = 1 / max_numbers_per_row;
    }
    //console.log(number_pick_width_percent);
    let styles__number__view =
    {
        flex: number_pick_width_percent,
    }
    //console.log(styles__number__view);


    let first_line__ui = [];

    //first line        
    if (props.first_line_picks.length > 0)
    {
        let first_line_rows = props.first_line_picks.length / max_numbers_per_row;
        first_line_rows = Math.ceil(first_line_rows);
        //console.log("first_line_rows", first_line_rows);

        let first_line_index = -1;
        for (let row_index = 1; row_index <= first_line_rows; row_index++)
        {
            //console.log("row_index", row_index);

            let first_line_row_ui = [];
            for (let row_column = 0; row_column < max_numbers_per_row; row_column++)
            {
                first_line_index++;
                if (props.first_line_picks.length > first_line_index)
                {
                    first_line_row_ui.push(
                        <div key={first_line_index} style={styles__number__view}>
                            <span>{props.first_line_picks[first_line_index]}</span>
                        </div>
                    );
                }
            }
            //console.log("first_line_row_ui", first_line_row_ui.length, first_line_row_ui);


            first_line__ui.push(
                <div key={row_index} className="game-line first-line">
                    {first_line_row_ui}
                </div>);
        }

        //console.log("first_line__ui", first_line__ui.length);
    }




    return (
        <div className="game-picks">

            {first_line__ui}

            {props.second_line_picks.length > 0 &&
                <>
                    <div className="game-line second-line">
                        {props.second_line_picks.map((item, index) =>
                        {
                            return (
                                <div key={index} style={styles__number__view}>
                                    <span >{item}</span>
                                </div>
                            )
                        })
                        }
                    </div>
                </>
            }
        </div>
    );
}