//import { console.log } from "./utilities/misc";
import { load_saved_data_by_game_id } from "../../utilities/data";


export async function get_game_analysis(game)
{
    //get picks specific to this game
    let game_picks = await load_saved_data_by_game_id(game.id);
    //console.log("game_picks", game_picks);    

    return get_game_analysis_from_picks(game, game_picks);
}


export async function get_game_analysis_from_picks(game, game_picks)
{
    let top_picks = [];


    //get all of the pick methods from the game picks    
    let pick_methods = [];
    if (game_picks && game_picks.length)
    {
        pick_methods = [0]; //0 will represent ALL

        //add additional pick methods based on what the player has played
        for (let pick_index = 0; pick_index < game_picks.length; pick_index++)
        {
            if (game_picks[pick_index].pick_method)
            {
                if (pick_methods.indexOf(game_picks[pick_index].pick_method) < 0)
                {
                    pick_methods.push(game_picks[pick_index].pick_method);
                }
            }
        }
    }
    //console.log("picks methods", pick_methods);
    //return;


    //get results for each pick method    
    for (let pick_method of pick_methods)
    {
        //console.log("pick method: " + pick_method);
        let top_pick = null;
        if (game.first_line.unique_numbers === false)
        {
            //console.log("get_top_pick: use nonunique numbers");
            top_pick = get_top_pick__nonunique_numbers__order_doesnt_matter(game, game_picks, pick_method);
        }
        else
        {
            //console.log("get_top_pick: use default");
            top_pick = get_top_pick__unique_numbers__order_doesnt_matter(game, game_picks, pick_method);
        }

        //console.log("top_pick", top_pick);
        if (top_pick)
        {
            top_picks.push(top_pick);
        }
    }


    //console.log("FINAL ANALYSIS", top_picks);
    return top_picks;
}


//this is the standard analysis - ny lotto - unique numbers, order doesn't matter
function get_top_pick__unique_numbers__order_doesnt_matter(game, game_picks, pick_method)
{
    let number_of_picks = 0;
    let first_line_numbers = [];
    let second_line_numbers = [];


    //get number of picks made for this pick method
    //save first line numbers
    //save second line numbers
    for (let game_pick of game_picks)
    {
        //pick_method === 0 is for ALL
        if (pick_method === 0 || game_pick.pick_method === pick_method)
        {
            //counter of number of picks
            number_of_picks++;

            //array of all first line numbers
            first_line_numbers.push(...game_pick.first_line);

            //if second line exists
            if (game_pick.second_line && game_pick.second_line.length)
            {
                //array of all second line numbers
                second_line_numbers.push(...game_pick.second_line);
            }
        }
    }
    //console.log("number_of_picks: " + number_of_picks);
    //console.log("first_line_numbers", first_line_numbers);
    //console.log("second_line_numbers", second_line_numbers);
    // return;


    let top_picks_overall__first_line = get_top_line_picks(first_line_numbers, game.first_line.number_of_picks);
    let top_picks_overall__second_line = get_top_line_picks(second_line_numbers, game.second_line.number_of_picks);
    //console.log("top_picks_overall__first_line", top_picks_overall__first_line);
    //console.log("top_picks_overall__second_line", top_picks_overall__second_line);


    let top_pick = {
        game: game,
        pick_method: pick_method,
        number_of_picks: number_of_picks,
        first_line: top_picks_overall__first_line,
        second_line: top_picks_overall__second_line
    }


    return top_pick;
}


//NY Numbers - numbers can all be the same, order doesn't matter
function get_top_pick__nonunique_numbers__order_doesnt_matter(game, game_picks, pick_method)
{
    let number_of_picks = 0;
    //get number of picks made for this pick method    
    for (let game_pick of game_picks)
    {
        //pick_method === 0 is for ALL
        if (pick_method === 0 || game_pick.pick_method === pick_method)
        {
            //counter of number of picks
            number_of_picks++;
        }
    }


    //we need top numbers per index (top number in position 1, 2, 3)
    //these need to be arrays of arrays
    let first_line_numbers = [];
    let second_line_numbers = [];
    for (let game_pick of game_picks)
    {
        //pick_method === 0 is for ALL
        if (pick_method === 0 || game_pick.pick_method === pick_method)
        {
            first_line_numbers.push(game_pick.first_line);

            //if second line exists
            if (game_pick.second_line && game_pick.second_line.length)
            {
                //array of all second line numbers
                second_line_numbers.push(game_pick.second_line);
            }
        }
    }
    // console.log("number_of_picks: " + number_of_picks);
    // console.log("first_line_numbers", first_line_numbers);
    // console.log("second_line_numbers", second_line_numbers);
    //return null;


    //for each index/position in first/second line picks, get the single top pick
    let top_picks_overall__first_line = get_top_line_picks_per_index(first_line_numbers);
    let top_picks_overall__second_line = get_top_line_picks_per_index(second_line_numbers);
    //console.log("top_picks_overall__first_line", top_picks_overall__first_line);
    //console.log("top_picks_overall__second_line", top_picks_overall__second_line);


    let top_pick = {
        game: game,
        pick_method: pick_method,
        number_of_picks: number_of_picks,
        first_line: top_picks_overall__first_line,
        second_line: top_picks_overall__second_line
    }


    return top_pick;
}


function get_top_line_picks_per_index(array_of_arrays)
{
    //console.log("get_top_line_picks_per_index", array_of_arrays);


    let final_top_picks = [];

    if (array_of_arrays && array_of_arrays.length > 0)
    {
        for (let position_index = 0; position_index < array_of_arrays[0].length; position_index++)
        {
            //we are getting all first lines with the same index
            //console.log("position_index", position_index);

            let numbers_per_index = [];
            for (let line_numbers of array_of_arrays)
            {
                //console.log("line_numbers", line_numbers);

                //get pick based on current position index
                if (line_numbers.length > position_index)
                {
                    numbers_per_index.push(line_numbers[position_index]);
                }
            }
            //console.log("numbers_per_index", "position_index: " + position_index, numbers_per_index);


            //get 1 get_top_line_picks from numbers_per_index
            let top_number = get_top_line_picks(numbers_per_index, 1);
            //console.log("top_number", top_number);

            //add this single top number to the final picks
            if (top_number && top_number.length > 0)
            {
                final_top_picks.push(top_number[0]);
            }
        }
    }


    //console.log("final_top_picks", final_top_picks);
    return final_top_picks;
}


function get_top_line_picks(numbers, number_of_picks_per_line)
{
    //console.log("get_top_line_picks", numbers);
    // console.log("get_top_line_picks", numbers.length);
    // console.log("number_of_picks_per_line", number_of_picks_per_line);

    let counts = [];
    for (let number_index = 0; number_index < numbers.length; number_index++)
    {
        let number = numbers[number_index];

        let number_found = false;
        let number_item = {
            number: number,
            count: 1,
        };

        for (let count_index = 0; count_index < counts.length; count_index++)
        {
            if (counts[count_index].number === number)
            {
                number_found = true;
                counts[count_index].count++
                break;
            }
        }

        if (number_found === false)
        {
            counts.push(number_item);
        }
    }

    //console.log("counts", counts);
    //sort by count
    counts = counts.sort((a, b) => (a.count < b.count) ? 1 : -1);
    //console.log("counts", counts);


    //take the top picks based on number_of_picks_per_line
    let top_picks = [];
    if (counts.length >= number_of_picks_per_line)
    {
        for (let x = 0; x < number_of_picks_per_line; x++)
        {
            top_picks.push(counts[x].number);
        }
    }


    //sort asc
    top_picks = top_picks.sort((a, b) => (a >= b) ? 1 : -1);


    //console.log("top_picks", top_picks);
    return top_picks;
}
