
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

//redux
//useSelector, useDispatch only works in functional components
import { useSelector, useDispatch } from "react-redux";
import { picks__list } from "../../../redux/actions/picksActions";


import "./header.scss";

export default function Header() {
    //console.log("header");
    //this is the item in the store you are using (state.users.items)
    const picks_count = useSelector(state => state.picks.count);

    //this is used to call an action (populate the users in this case)
    const dispatch = useDispatch();


    useEffect(() => {
        //on initial load, call the users list action
        dispatch(picks__list());
    });


    //<div className="spacer"></div>
    //<img alt="header logo" title="header logo" src="/images/header-logo-01.png" />


    return (
        <header className="app-header">
            <a style={{ zIndex: 1000 }} href="/" alt="Go Home" title="View All Games">
                <img alt="Go Home" title="Go Home" src="/images/logo512.png" />
            </a>

            <div className="links">
                <div>
                    <Link to="/games/" alt="View All Games" title="View All Games">Games</Link>
                </div>
                <div>
                    <Link to="/my-picks/" alt="View My Picks" title="View My Picks">My Picks ({picks_count})</Link>
                </div>
                <div>
                    <Link to="/purchase-tickets/" alt="Purchase Tickets and Play Lotto Online" title="Purchase Tickets and Play Lotto Online">Purchase Tickets</Link>
                </div>
            </div>
        </header>
    )
}