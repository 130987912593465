//import * as strings from "./strings";
//import { console.log } from "./utilities/misc";
import games_json from "./json/games.json";
//import languages_json from "./json/languages.json";
//import translations_json from './json/translations.json';
import pick_methods_json from './json/pick_methods.json';


//////////////////////////////////////////////////////////////////////////////// games
export function set__games(value) {
    //console.log("globals: set__games", value);
    if (value) {
        value = value.filter(x => x.id > 0);
        //console.log("value", value);

        global.games = value;
    }
}
export function get__games() {
    // console.log("get__games");
    // console.log(global.games);

    if (global && global.games && global.games.length) {
        return global.games;
    }

    // console.log("return json games");
    // console.log(games_json);
    return games_json;
}



//////////////////////////////////////////////////////////////////////////////// preferred language
export function set__preferred_language(value) {
    global.preferred_language = value;
}
export function get__preferred_language() {
    if (global && global.preferred_language && global.preferred_language !== "") {
        return global.preferred_language;
    }

    return "";
}




//////////////////////////////////////////////////////////////////////////////// device language
export function set__device_language(value) {
    global.device_language = value;
}
export function get__device_language() {
    if (global && global.device_language) {
        return global.device_language;
    }
    return "";
}



//////////////////////////////////////////////////////////////////////////////// translations
// export function set__translations(value) {
//     //console.log("set__translations", value);
//     global.translations = value;
// }
// export function get__translations() {
//     if (global && global.translations && global.translations.length) {
//         return global.translations;
//     }


//     return translations_json;
// }




//////////////////////////////////////////////////////////////////////////////// languages
// export function set__languages(value) {
//     //console.log("set__languages", value);
//     global.languages = value;
// }
// export function get__languages() {
//     if (global && global.languages && global.languages.length) {
//         return global.languages;
//     }


//     return languages_json;
// }




//////////////////////////////////////////////////////////////////////////////// pick methods
export function set__pick_methods(value) {
    //console.log("set__pick_methods", value);
    global.pick_methods = value;
}
export function get__pick_methods() {
    // if (global && global.pick_methods && global.pick_methods.length)
    // {
    //     return global.pick_methods;
    // }
    return pick_methods_json;
}


let timers__human_capable_tick_in_milliseconds = 100;

export function set__timers__human_capable_tick_in_milliseconds(value) {
    //console.log("set__timers__human_capable_tick_in_milliseconds", value);    
    timers__human_capable_tick_in_milliseconds = value;
}


export function get__timers__human_capable_tick_in_milliseconds() {

    return 125;
    return 100;
    return 150;
    //console.log("get__timers__human_capable_tick_in_milliseconds", timers__human_capable_tick_in_milliseconds);
    if (timers__human_capable_tick_in_milliseconds > 0) {
        return timers__human_capable_tick_in_milliseconds;
    }


    return 100;
}