
import * as ActionTypes from "../actions/types";


const initialState = {    
    loading: false,    
    error: null,
    errors: []    
}


const standardActionsReducer = (state = initialState, action) =>
{
    //console.log("usersReducer: " + action.type);

    switch (action.type)
    {
        case ActionTypes.STANDARD_ACTIONS__BEGIN:
            return {
                ...state,
                loading: true,
                errors: []
            };

        case ActionTypes.STANDARD_ACTIONS__FAILED:
            //console.log(action);

            return {
                ...state,
                loading: false,
                errors: action.payload
            };

        case ActionTypes.STANDARD_ACTIONS__SUCCESS:
            return {
                ...state,
                loading: false,
                errors: [],
            };
        

        default:
            return state;
    }
}

export default standardActionsReducer;