
import { meta_set } from "../../../api/utilities/pages";
import "./translations.scss";

export default function Translations()
{
    meta_set({
        title: "Translations",
        description: "Translation issues? Let us know.",
        url_path: "/translations/",
        image_url: "",
    });

    return (
        <div className="page-feedback">
            <h1>Translation Issues</h1>

            <iframe width="100%" height="800" title="Lucky Lotto Number Picker Feedback" src="https://s.surveyplanet.com/yvtlthg6" />
        </div>
    )
}