import React, { Component } from "react";

import GamePicks from "../../../../../components/GamePicks/GamePicks";

import { get_random_numbers_by_game } from "../../../../../../api/utilities/from-app/games";
import { random__get_range_v1 } from "../../../../../../api/utilities/from-app/math";
import pick_methods from "../../../../../../api/utilities/from-app/enums/pick_methods.json";
import * as globals from "../../../../../../api/globals";

import "./tap-quick.scss";


export default class PlayTapPick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picks_made: 0,
            current_number: 0,
            current_line: "",
            first_line_picks: [],
            second_line_picks: [],
            first_line_numbers: [],
            second_line_numbers: [],
            game_over: false,
            show_clicked_state: false,

            completed_rounds: 0,
        };

        this.pick_method = pick_methods.tap;


        //number of rounds
        this.number_of_rounds = 1;
        if (this.props.pick_details && this.props.pick_details.number_of_rounds) {
            this.number_of_rounds = this.props.pick_details.number_of_rounds;
            if (this.number_of_rounds <= 0) {
                this.number_of_rounds = 1;
            }
        }
        //console.log("this.number_of_rounds: " + this.number_of_rounds);        


        //this is state but we need to ensure its value is set immediately
        this.ready_for_next_pick = false;
        this.picks_saved = false;


        this.timer_tick = this.timer_tick.bind(this);


        this.start_picker = this.start_picker.bind(this)
        this.save_picks = this.save_picks.bind(this);
        this.make_pick = this.make_pick.bind(this);

        //custom to this picker
        this.show_random_number = this.show_random_number.bind(this);
    }


    ///////////////////////////////////////////////////////////// everything here is custom to this picker
    timer_tick() {
        //console.log("timer_tick");

        if (this.state.game_over) {
            return;
        }


        this.show_random_number();
    }


    show_random_number() {
        //show random number

        let new_number = 0;
        let current_line = "first";

        //first line picks
        if (this.state.first_line_picks.length < this.props.game.first_line.number_of_picks) {
            let rnd_index = random__get_range_v1(0, this.state.first_line_numbers.length - 1);
            new_number = this.state.first_line_numbers[rnd_index];
        }
        else if (this.state.second_line_picks.length < this.props.game.second_line.number_of_picks) {
            current_line = "second";
            let rnd_index = random__get_range_v1(0, this.state.second_line_numbers.length - 1);
            new_number = this.state.second_line_numbers[rnd_index];
        }


        this.setState({
            current_number: new_number,
            current_line: current_line,
        });
    }


    after_make_pick() {
        //console.log("after_make_pick");
        // console.log("this.props.preferences__vibrate", this.props.preferences__vibrate);

        // if (
        //     this.props.preferences__vibrate === true
        //     && this.state.game_over === false
        // )
        // {
        //     console.log("vibration allowed");

        //     try
        //     {
        //         //console.log("vibrate");
        //         //Vibration.vibrate(100)
        //     }
        //     catch (err)
        //     {
        //         console.error(err);
        //     }
        // }
    }



    ///////////////////////////////////////////////////////////// everything below here is same across pickers    
    componentDidMount() {
        this.start_picker();
    }


    componentWillUnmount() {
        //console.log("componentWillUnmount");
        if (this.timer) {
            //console.log("componentWillUnmount: clearTimeout");
            clearTimeout(this.timer);
        }
    }


    start_picker() {
        //get random numbers based on the game
        var rnd_numbers = get_random_numbers_by_game(this.props.game);

        //reset state  
        this.ready_for_next_pick = false;
        this.picks_saved = false;

        this.setState({
            picks_made: 0,
            current_number: 0,
            current_line: "",
            first_line_picks: [],
            second_line_picks: [],
            first_line_numbers: rnd_numbers.first_line_numbers,
            second_line_numbers: rnd_numbers.second_line_numbers,
            game_over: false,
            picks_saved: false,
        }, function () {
            //starts the process
            this.timer = setInterval(this.timer_tick, globals.get__timers__human_capable_tick_in_milliseconds());
            this.ready_for_next_pick = true;
        });
    }


    make_pick = () => {
        //this code is the same for pick methods
        //console.log("make_pick");
        //return;


        if (this.state.game_over) {
            //this is used to ensure additional picks are not made between game over and timer stopping
            //console.log("game over");
            return;
        }


        if (this.ready_for_next_pick === false) {
            console.log("timer_tick: NOT READY FOR NEXT PICK");
            return;
        }


        this.setState({
            show_clicked_state: true,
        });


        //console.log(this.state);
        this.ready_for_next_pick = false;


        let first_line_picks = this.state.first_line_picks;
        let second_line_picks = this.state.second_line_picks;

        let first_line_numbers = this.state.first_line_numbers;
        let second_line_numbers = this.state.second_line_numbers;
        // console.log("First line number: " + first_line_numbers.length);
        // console.log("Second line number: " + second_line_numbers.length);



        //first line picks
        if (this.state.first_line_picks.length < this.props.game.first_line.number_of_picks) {
            //get new first line pick
            //console.log("new first line pick");
            let rnd_index = random__get_range_v1(0, this.state.first_line_numbers.length - 1);
            first_line_picks.push(this.state.first_line_numbers[rnd_index]);

            if (this.props.game.first_line.unique_numbers) {
                //console.log("remove first line index: " + rnd_index);
                first_line_numbers.splice(rnd_index, 1);
            }
        }
        else if (this.state.second_line_picks.length < this.props.game.second_line.number_of_picks) {
            //get new second line pick
            //console.log("new second line pick");
            let rnd_index = random__get_range_v1(0, this.state.second_line_numbers.length - 1);
            second_line_picks.push(this.state.second_line_numbers[rnd_index]);

            if (this.props.game.second_line.unique_numbers) {
                //console.log("remove second line index: " + rnd_index);
                second_line_numbers.splice(rnd_index, 1);
            }
        }



        //game over:
        let game_over = false;
        if (first_line_picks.length >= this.props.game.first_line.number_of_picks
            && second_line_picks.length >= this.props.game.second_line.number_of_picks
        ) {
            //game over
            //console.log("game over");
            game_over = true;
        }


        this.setState({
            game_over: game_over,
            first_line_picks: first_line_picks,
            second_line_picks: second_line_picks,
            first_line_numbers: first_line_numbers,
            second_line_numbers: second_line_numbers
        },
            function () {
                if (game_over) {
                    this.save_picks();
                }
                else {
                    this.ready_for_next_pick = true;

                    setTimeout(() => {
                        //wait to show normal background
                        this.setState({
                            show_clicked_state: false,
                        });
                    }, 100)
                }
            }
        );


        this.after_make_pick();
    }


    async save_picks() {
        //console.log("PlayTapPick: save_picks");

        if (this.picks_saved) {
            console.log("picks already saved");
            return;
        }


        this.picks_saved = true;

        //console.log("stop timer");
        if (this.timer) {
            clearTimeout(this.timer);
        }


        //sort the picks                
        var first_line_picks = this.state.first_line_picks;
        first_line_picks.sort((a, b) => a < b ? -1 : 1);
        // console.log(this.state.first_line_picks);
        // console.log(first_line_picks);

        var second_line_picks = this.state.second_line_picks;
        second_line_picks.sort((a, b) => a < b ? -1 : 1);
        // console.log(this.state.second_line_picks);
        // console.log(second_line_picks);


        //are we done?
        var rounds_completed = true;
        var completed_rounds = this.state.completed_rounds;
        completed_rounds++;

        if (completed_rounds < this.number_of_rounds) {
            rounds_completed = false;
        }
        // console.log("Start new round? " + completed_rounds);
        // console.log("rounds_completed? " + rounds_completed);


        //save pick
        await this.props.onComplete(this.props.game, this.pick_method, this.state.first_line_picks, this.state.second_line_picks, rounds_completed);


        this.setState({
            completed_rounds: completed_rounds
        }, function () {
            if (rounds_completed === false) {
                console.log("Start a new round");
                this.start_picker();
            }
        })
    }


    render() {
        //console.log(this.state);

        return (
            <div className="pick-method-tap">
                {this.state.game_over === false &&
                    <div className="tap-wrapper" onClick={() => this.make_pick()} data-clicked-state={this.state.show_clicked_state}>

                        <div className="balls">
                            <div className="non-ball"></div>
                            <div className="non-ball"></div>

                            {this.state.current_line === "first" &&
                                <div className="ball" alt="Primary Pick" title="Primary Pick" style={{ backgroundImage: "url('/images/balls--primary-01.png')" }} >
                                    <span>{this.state.current_number}</span>
                                </div>
                            }
                            {this.state.current_line === "second" &&
                                <div className="ball" alt="Secondary Pick" title="Secondary Pick" style={{ backgroundImage: "url('/images/balls--secondary-01.png')" }} >
                                    <span>{this.state.current_number}</span>
                                </div>
                            }

                            <div className="non-ball"></div>
                            <div className="non-ball"></div>
                        </div>

                        <div className="tap-to-pick">TAP/CLICK IN BOX TO PICK</div>

                    </div>
                }

                <div style={{marginTop: 40}}>
                    <GamePicks game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
                </div>

            </div>
        );
    }
}