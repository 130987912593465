
import * as ActionTypes from "../actions/types";


const initialState = {
    item: null,
    items: [],    
    loading: false,
    error: null
}


const gamesReducer = (state = initialState, action) =>
{
    switch (action.type)
    {        
        case ActionTypes.GAMES__LIST__SUCCESS:            
            return {
                ...state,
                loading: false,
                items: action.payload
            };

        default:
            return state;
    }
}

export default gamesReducer;