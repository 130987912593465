import React from "react";
import { Link } from "react-router-dom";

import GamesHeader from "./../components/GamesHeader/GamesHeader";
import GamePicks from "../../../components/GamePicks/GamePicks";

import GamesList from "../../../../api/json/games.json";
import { get_url_title } from "../../../../api/utilities/strings";
import { get_game_analysis } from "../../../../api/utilities/from-app/analyze";
import { get_pick_method_name } from "../../../../api/utilities/from-app/games";


import "./../styles/games-details.scss";
import { meta_set } from "../../../../api/utilities/pages";

class GamesAnalyze extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            game: null,
            top_picks: null,
        };
    }


    async componentDidMount()
    {
        //console.log(this.props);       
        let id = "";
        if (this.props.match
            && this.props.match.params
            && this.props.match.params.id)
        {
            id = this.props.match.params.id;
        }
        //console.log(id);


        let game = null;
        if (id)
        {
            for (let item of GamesList)
            {
                if (get_url_title(item.name) === id)
                {
                    game = item;
                    break;
                }
            }
        }
        //console.log(game);


        let top_picks = null;
        if (game)
        {
            top_picks = await get_game_analysis(game);
            //console.log("top_picks", top_picks);               
        }


        this.setState({
            game: game,
            top_picks: top_picks
        });
    }

    render()
    {
        if (!this.state.game || !this.state.top_picks)
        {
            return null;
        }

        meta_set({
            title: this.state.game.name + ": Analyze Your Numbers",
            description: "Analyze your lucky lotto numbers for " + this.state.game.name + ".",
            url_path: "/games/" + get_url_title(this.state.game.name) + "/analyze/",
            image_url: "",
        });


        return (
            <div className="page-game-analyze" >
                <GamesHeader game={this.state.game} tab="analyze" />

                <div>

                    {this.state.top_picks.length === 0 &&
                        <div className="no-picks-yet">
                            <span>You haven't made any picks yet. Click <Link to={"/games/" + get_url_title(this.state.game.name) + "/"} alt="Make Picks" title="Make Picks">"Play"</Link> to start making picks.</span>
                        </div>
                    }

                    {this.state.top_picks.map((item, index) =>
                    {
                        // console.log("mapping");
                        //console.log(item);
                        // console.log(this.props.game);

                        if (item.pick_method === null)
                        {
                            return null;
                        }

                        let pick_method = get_pick_method_name(item.pick_method);
                        if (!pick_method)
                        {
                            console.log("pick method not found");
                            return null;
                        }

                        pick_method += " (" + item.number_of_picks + ")";


                        return (
                            <div key={index} style={{ marginBottom: 40, textAlign: "center" }}>
                                <div style={{ marginBottom: 10 }}>
                                    <span style={{ fontFamily: "Lobster" }}>{pick_method}</span>
                                </div>
                                <GamePicks game={item.game} first_line_picks={item.first_line} second_line_picks={item.second_line} />
                            </div>
                        );
                    })
                    }
                </div>
            </div >
        );
    }
}
export default GamesAnalyze