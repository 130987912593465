import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { picks__list } from "../../../../../redux/actions/picksActions";
import { get_url_title } from "../../../../../api/utilities/strings";
import { data__delete_game_picks } from "../../../../../api/utilities/data";

import "./games-header.scss";


export default function GamesHeader(props)
{
    //console.log(props);

    const [jackpot, setJackpot] = useState(0);

    //this is the item in the store you are using (state.users.items)
    const all_picks = useSelector(state => state.picks);

    //this is used to call an action (populate the users in this case)
    const dispatch = useDispatch();


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() =>
    {
        //console.log("GameHeader: useEffect");
        //console.log(props);
        //console.log(props.game);
        //console.log(props.game.game_details_url);


        if (props.game.game_details_url)
        {
            try
            {
                fetch(props.game.game_details_url)
                    .then(response => response.json())
                    .then(data =>
                    {
                        //console.log(data);
                        if (data.current_jackpot)
                        {
                            //console.log("data.current_jackpot", data.current_jackpot);

                            setJackpot(data.current_jackpot);
                            //console.log(data.current_jackpot.toLocaleString("en-US"));
                        }
                    });
            }
            catch (err)
            {
                console.log(err);
            }
        }
    }, []);



    let jackpot_string = "";
    if (jackpot > 0)
    {
        //console.log("jackpot", jackpot);        
        jackpot_string = "$" + jackpot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //console.log("jackpot_string", jackpot_string);
    }



    //console.log("all_picks", all_picks);
    let picks_count = 0;
    if (all_picks)
    {
        let game_picks = all_picks.items.filter(x => x.game_id === props.game.id);
        //console.log("game_picks", game_picks);
        picks_count = game_picks.length;
    }


    //console.log(props.game);
    const onClick_delete_game_picks = (game) =>
    {
        if (picks_count === 0)
        {
            return;
        }


        if (window.confirm("Are you sure? This cannot be undone."))
        {
            //return;

            //delete picks
            data__delete_game_picks(game);

            //update redux - only doing this for the header            
            try
            {
                dispatch(picks__list());
            }
            catch (err)
            {
                console.error(err);
            }
        }
    }


    return (
        <div className="games-header" id="games-header">
            <div className="top-functions">
                <button data-has-picks={picks_count > 0 ? "true" : "false"} className="delete" type="button" onClick={() => onClick_delete_game_picks(props.game)}>Delete Game Picks ({picks_count})</button>
            </div>

            <div className="title-line">
                {props.game.image_url &&
                    <img src={props.game.image_url} alt={props.game.name} title={props.game.name} />
                }
                <h1 className="page-h1" alt={props.game.name} title={props.game.name}>{props.game.name}</h1>
            </div>

            <div className="info">

                <div className="drawing-days">Drawing Days: {props.game.drawing_days}</div>

                {jackpot_string.length > 0 &&
                    <div className="current-jackpot">
                        <span>{jackpot_string}</span>
                    </div>
                }

                <div className="rules">
                    <div className="game-line first-line">
                        <div className="number-of-picks">Number of Picks: {props.game.first_line.number_of_picks}</div>
                        <div className="range">Range: {props.game.first_line.range_start}-{props.game.first_line.range_end}</div>
                    </div>

                    {props.game.second_line.number_of_picks > 0 &&
                        <div className="game-line second-line">
                            <div className="number-of-picks">Number of Picks: {props.game.second_line.number_of_picks}</div>
                            <div className="range">Range: {props.game.second_line.range_start}-{props.game.second_line.range_end}</div>
                        </div>
                    }
                </div>

            </div>

            <div className="links">
                <Link to={"/games/" + get_url_title(props.game.name) + "/"} className={props.tab === "details" ? "selected" : ""} >Play</Link>
                <Link to={"/games/" + get_url_title(props.game.name) + "/analyze/"} className={props.tab === "analyze" ? "selected" : ""}>Analyze</Link>

                {props.game.winning_numbers__recent_url &&
                    <Link to={"/games/" + get_url_title(props.game.name) + "/recent-winning-numbers/"} className={props.tab === "recent winning numbers" ? "selected" : ""}>Winning Numbers</Link>
                }
            </div>

        </div>
    )
}