export const STANDARD_ACTIONS__BEGIN = "STANDARD_ACTIONS__BEGIN";
export const STANDARD_ACTIONS__FAILED = "STANDARD_ACTIONS__FAILED";
export const STANDARD_ACTIONS__SUCCESS = "STANDARD_ACTIONS__SUCCESS";


////////////////////////////////////////////////////////////////////////// GAMES
export const GAMES__LIST__SUCCESS = "GAMES__LIST__SUCCESS";




////////////////////////////////////////////////////////////////////////// PICKS
export const PICKS__LIST__SUCCESS = "PICKS__LIST__SUCCESS";