
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers"; //webpack automatically checks index.js
import { composeWithDevTools } from 'redux-devtools-extension';

//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const middleware = [
    thunk,
];


//////////////////////////////////REDUX IS DIFFERENT HERE THAN IN COINCHAT



//export default process.env.REACT_APP_ENV === "production" ? prod_site_config : dev_site_config;

let store = null;

if (process.env.REACT_APP_ENV === "production")
{
    //console.log("prod");
    store = createStore(
        rootReducer,
        applyMiddleware(...middleware)        
    );

    //export default store;
}
else
{
    //console.log("not prod");
    store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(...middleware))
    );

    //export default store;
}

// const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(...middleware))
// );

export default store;