import React from "react";

//redux related
import { connect } from "react-redux";

//import GamePicks from "../../components/GamePicks/GamePicks";
import AgeDisclaimer from "../../components/AgeDisclaimer/AgeDisclaimer";

import GamesList from "../../../api/json/games.json";
//import { get_game_by_id, get_pick_method_name } from "../../../api/utilities/from-app/games";
import { local_storage__get_object } from "../../../api/utilities/browser";
import cache_keys from "../../../api/cache-keys";

//console.log(GamesList);

import "./styles/purchase-tickets.scss";
import { meta_set } from "../../../api/utilities/pages";


class PurchaseTickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_tab: "all",
            sections: [],
            all_games: [],
            games: [],
            all_favorites: [],
            favorites: [],
        };
    }


    async componentDidMount() {

        meta_set({
            title: "Purchase Tickets",
            description: "Purchase tickets online.",
            url_path: "/purchase-tickets/",
            image_url: "",
        });

        let all_games = [];
        let all_favorites = [];
        let sections = ["All"];



        //get games and sections
        for (let game of GamesList) {
            //console.log(game);
            if (!game.thelotter_url || game.thelotter_id === 0) {
                continue;
            }


            //console.log(game);
            all_games.push(game);

            if (sections.includes(game.tab_section) === false) {
                sections.push(game.tab_section);
            }
        }
        all_games.sort((a, b) => a.name < b.name ? -1 : 1);
        //console.log(sections);
        //console.log(all_games);


        //get favorites
        var storage_favorites = local_storage__get_object(cache_keys.local_storage__games__favorites);
        //console.log(storage_favorites);
        if (storage_favorites) {
            all_favorites = storage_favorites;
        }



        this.setState(
            {
                sections: sections,
                all_games: all_games,
                games: all_games,
                all_favorites: all_favorites,
            }
        );

        window.scrollTo(0, 0);
    }


    onClick_tab = (tab) => {
        //console.log("onClick_tab: " + tab);
        this.load_tab(tab);
    }


    load_tab = (tab) => {
        //console.log("load_tab: " + tab);

        if (!tab) {
            tab = "all";
        }
        tab = tab.toLowerCase();


        //get games
        let games = [];
        if (tab === "all") {
            games = this.state.all_games.filter(x => x.id > 0);
        }
        else {
            games = this.state.all_games.filter(x => x.tab_section.toLowerCase() === tab);
        }


        //sort games
        games.sort((a, b) => a.name < b.name ? -1 : 1);
        //console.log(games);


        this.setState({
            games: games,
            selected_tab: tab
        });
    }


    render() {
        //console.log(this.state);


        let language_id = 1; //thelotter: english


        //<h1 className="page-h1" alt="Purchase Tickets Online Through TheLotter" title="Purchase Tickets Online Through TheLotter">Purchase Tickets Online Through <a target="_blank" rel="noreferrer" alt="Purchase Tickets Online Through TheLotter" title="Purchase Tickets Online Through TheLotter" href="https://smarturl.it/HomePage_Play?IQid=theLotter&tl_affid=14841">TheLotter.com</a></h1>

        return (
            <div className="page-purchase-tickets games-all-games">
                <h1 className="page-h1" alt="Purchase Tickets" title="Purchase Tickets">Purchase Tickets</h1>

                <AgeDisclaimer />

                <div className="jackpocket">
                    <h3>Purchase Tickets at:</h3>
                    <a href="https://www.jackpocket.com/referrals/pmzd6n" target="_blank" alt="Play at JackPocket" title="Play at JackPocket">
                        <img src="/images/jackpocket.png" alt="Play at JackPocket" title="Play at JackPocket" />
                    </a>
                </div>


                <div className="game-sections">
                    {this.state.sections.map((section) => {
                        //console.log(game);
                        let selected = false;
                        if (this.state.selected_tab.toLowerCase() === section.toLowerCase()) {
                            selected = true;
                        }

                        return (
                            <button data-selected={selected} alt={section} title={section} key={section} type="button" onClick={() => this.onClick_tab(section)}>{section}</button>
                        )
                    })
                    }
                </div>


                <div className="the-lotter">
                    <h3 alt="Purchase Tickets Online Through TheLotter" title="Purchase Tickets Online Through TheLotter">Purchase Tickets at: <a target="_blank" rel="noreferrer" alt="Purchase Tickets Online Through TheLotter" title="Purchase Tickets Online Through TheLotter" href="https://smarturl.it/HomePage_Play?IQid=theLotter&tl_affid=14841">TheLotter.com</a></h3>
                </div>

                <div className="games-list">
                    {this.state.games.map((game) => {
                        return (
                            <div key={game.id}>
                                <h2 alt={game.name} title={game.name}>{game.name}</h2>
                                <a target="_blank" href={game.thelotter_url} rel="nofollow sponsored" alt={"Play " + game.name + " Online"} title={"Play " + game.name + " Online"}>
                                    <img src={"https://www.tl-res.com/banners/" + game.thelotter_id + "/" + language_id + "/728/90/banner.jpeg"} alt={"Play " + game.name + " Online"} title={"Play " + game.name + " Online"} />
                                </a>
                            </div>
                        )
                    })
                    }
                </div >

            </div >
        );
    }
}
//export default MyPicks
//these are the state properties you want access to
//these will get added to this.props
const mapStateToProps = state => ({
    picks: state.picks.items
});


export default connect(mapStateToProps)(PurchaseTickets);