

import { strings__clean, strings__is_string_empty } from "./strings";
//import site_config from "../_config/site-config";


export function css__add_body_css(css_to_add)
{
    try
    {
        let current_css = document.querySelector("body").getAttribute("class");
        //console.log("current_css:", current_css);    

        let previous_page_css = document.querySelector("body").getAttribute("data-page-css");
        //console.log("previous_page_css:", previous_page_css);


        var new_css = current_css;
        //console.log("new_css:", new_css);

        //remove previous_age_css from new_css
        if (previous_page_css !== null && previous_page_css !== undefined && previous_page_css !== "")
        {
            new_css = new_css.replaceAll(previous_page_css, "");
        }
        new_css = strings__clean(new_css);
        //console.log("new_css:", new_css);


        new_css += " " + css_to_add;
        new_css = strings__clean(new_css);
        //console.log("new_css:", new_css);


        //set class
        document.querySelector("body").setAttribute("class", new_css);
    } catch (err)
    {
    }
}




export function meta_set(meta_data)
{    
    meta_set_title(meta_data.title);    
    meta_set_description(meta_data.description);
    meta_set_canonical_url(meta_data.url_path);
    meta_set_image_url(meta_data.image_url);
    //create keywords from title and description
    //type
    //<meta property="og:type" content="article" />
}


export function meta_set_title(title)
{    
    if (!title)
    {
        return;
    }

    //console.log("meta_set_title");
    document.title = title;
    head_add_update_tag("meta", "name='title'", "content", title);
    head_add_update_tag("meta", "property='og:title'", "content", title);
    head_add_update_tag("meta", "name='twitter:title'", "content", title);

}


export function meta_set_description(description)
{
    if (!description)
    {
        return;
    }

    //console.log("meta_set_description");
    head_add_update_tag("meta", "name='description'", "content", description);
    head_add_update_tag("meta", "property='og:description'", "content", description);
    head_add_update_tag("meta", "name='twitter:description'", "content", description);
}


export function meta_set_keywords(keywords)
{
    //console.log("meta_set_description");
    head_add_update_tag("meta", "name='keywords'", "content", keywords);
}


export function meta_set_canonical_url(url_path)
{
    //console.log("meta_set_canonical_url");
    let url = "";
    url = window.location.protocol + '//' + window.location.host;
    if (url_path.startsWith("/") === false)
    {
        url += "/";
    }
    url += url_path;

    head_add_update_tag("link", "rel='canonical'", "href", url);
    head_add_update_tag("meta", "property='og:url'", "content", url);
}


export function meta_set_image_url(image_url)
{
    //console.log("meta_set_description");    
    head_add_update_tag("meta", "property='og:image'", "content", image_url);
    head_add_update_tag("meta", "name='twitter:image'", "content", image_url);
}


function head_add_update_tag(tag, selector, property, value)
{
    //console.log("head_add_update_tag");

    // Get an element if it exists already
    let element = document.querySelector(`${tag}[${selector}]`);

    // Check if the element exists
    if (element)
    {
        // If it does just change the content of the element
        element.setAttribute(property, value);
    }
    else
    {
        // It doesn't exist so lets make a HTML element string with the info we want
        element = `<${tag} ${selector} ${property}="${value}" />`;

        // And insert it into the head
        document.head.insertAdjacentHTML("beforeend", element);
    }
}