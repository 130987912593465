
import * as ActionTypes from "../actions/types";


const initialState = {
    item: null,
    items: [], 
    count: 0,   
    loading: false,
    error: null
}


const picksReducer = (state = initialState, action) =>
{
    switch (action.type)
    {        
        case ActionTypes.PICKS__LIST__SUCCESS:            
            return {
                ...state,
                loading: false,
                items: action.payload,
                count: action.payload.length,
            };

        default:
            return state;
    }
}

export default picksReducer;