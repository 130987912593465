
import { Link } from "react-router-dom";

//api
import { get_url_title } from "../../../../../api/utilities/strings";
import { is_playing_today } from "../../../../../api/utilities/from-app/games";


import "./game-item.scss";


export default function GameItem(props)
{
    // console.log("Game Item");
    // console.log(props.game);
    // console.log(props.all_favorites);

    let is_favorite = false;
    if (props.all_favorites)
    {
        is_favorite = props.all_favorites.includes(props.game.id);
    }
    //console.log(is_favorite);

    //var is_game_playing_today = is_playing_today(props.game);
    //console.log("is_game_playing_today", is_game_playing_today);

    return (
        <div className="game-item" data-is-favorite={is_favorite}>

            <div className="image">
                {props.game.image_url &&
                    <Link alt={props.game.name} title={props.game.name} to={"/games/" + get_url_title(props.game.name) + "/"}><img src={props.game.image_url} alt={props.game.name} title={props.game.name} /></Link>
                }
            </div>

            <div className="title">
                <h2 key={props.game.id} alt={props.game.name} title={props.game.name}><Link alt={props.game.name} title={props.game.name} to={"/games/" + get_url_title(props.game.name) + "/"}>{props.game.name}</Link></h2>
                <div className="details">
                    <div className="drawing-days">
                        {props.game.drawing_days}
                    </div>
                    {is_playing_today(props.game) === true &&
                        <div className="playing-today">Playing Today!</div>
                    }
                </div>
            </div>
            <div className="star">
                <i className="fas fa-star" onClick={() => { props.onClick_toggle_favorite(props.game); }}></i>
            </div>
        </div>
    );
}