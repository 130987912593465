
let pick =
{
    ts: 0,
    game: null, //legacy
    game_name: "",
    pick_method: 0,
    first_line: [],
    second_line: [],
};

export function get_default_pick()
{
    //return game;
    return JSON.parse(JSON.stringify(pick))
}