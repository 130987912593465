import { strings__clean, get_guid } from "./strings";
import cache_keys from "../cache-keys";
// import { dates__get_current } from "./dates";


export function redirect(new_url, calling_method)
{
    if (calling_method !== null && calling_method !== undefined && calling_method !== "")
    {
        console.log("redirected from : " + calling_method);
    }

    window.location = new_url;
}


export function get_query_string_value(key)
{
    var current_url = window.location.href;
    return get_query_string_value_from_url(current_url, key);
}


export function get_query_string_value_from_url(url, key)
{
    if (url === null || url === "")
    {
        return "";
    }


    //key = key.replace(/[\[\]]/g, '\\$&');
    key = key.replace(/[[]]/g, '\\$&');
    var regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function local_storage__get_object(key)
{
    try
    {
        var value = local_storage__get(key);
        if (value === null || value === undefined)
        {
            //console.log("store object is null");
            return null;
        }

        var model = JSON.parse(value);
        if (storage__model_is_valid(model) === false)
        {
            //console.log("object is foud but invalid");
            // console.log(model);
            return null;
        }


        // console.log("cached object");
        //console.log(model);
        //console.log(model.model);
        return model.model;
    }
    catch (ex)
    {
    }
}


function local_storage__get(key)
{
    try
    {
        if (typeof (Storage) !== "undefined")
        {
            return localStorage.getItem(key);
        }
        else
        {
        }
    }
    catch (ex)
    {
    }
}


export function local_storage__set_object(key, object, expiration_in_seconds)
{
    if (key === null || key === undefined)
    {
        //console.log("local_storage__set_object: key is null");
        return;
    }

    if (object === null || object === undefined)
    {
        //console.log("local_storage__set_object: object is null");
        local_storage__set(key, null);
        return;
    }

    try
    {
        var wrapper_model =
        {
            model: object
        };
        storage__set_expiration(wrapper_model, expiration_in_seconds);

        var value = JSON.stringify(wrapper_model);
        local_storage__set(key, value);
    }
    catch (ex)
    {
    }
}


function local_storage__set(key, value)
{
    try
    {
        localStorage.setItem(key, value);
    }
    catch (ex)
    {
    }
}


// function local_storage__remove(key)
// {
//     try
//     {

//     }
//     catch (ex)
//     {
//     }
// }


function storage__model_is_valid(model)
{
    if (model === null || model === undefined)
    {
        //alert("storage is null");
        return false;
    }

    if (model.Expires === null || model.Expires === undefined)
    {
        //alert("storage doesnt have expiration");
        return false;
    }

    var current_ts = new Date().getTime();
    if (model.Expires < current_ts)
    {
        //alert("storage has expired");
        return false;
    }

    //alert("storage is good");
    return true;
}


function storage__set_expiration(model, secondsToLive)
{
    //ts is milliseconds since epoch
    var ts = new Date().getTime();
    ts += secondsToLive * 1000;

    model.Expires = ts;

    return model;
}



/////////////////////////////////////////////////////////jquery equivalent
export function window_scroll_top()
{
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

    //var scrollLeft = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
    let scroll_top = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

    return scroll_top;
}


export function window_height()
{
    // let height = 0;

    // var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    //console.log("window_height: ", height);
    return height;
}


export function document_height()
{
    let height = document.body.clientHeight ||
        document.height;

    //console.log("document_height: ", height);
    return height;
}


export function cookies__set(key, value, expiration_date)
{
    //console.log(key, value, expiration_date);
    key = strings__clean(key);
    value = strings__clean(value);

    let cookie_value = key + "=" + value + "; path=/";
    //console.log(cookie_value);
    //console.log(document.cookie);
    document.cookie = cookie_value;
    //console.log(document.cookie);
}


export function cookies__get(key)
{
    //console.log(key);

    var cookie_values = document.cookie.split(';');
    //console.log(cookie_values);

    for (var x = 0; x < cookie_values.length; x++)
    {
        var cookie = cookie_values[x];
        cookie = strings__clean(cookie);
        //console.log(cookie);
        // console.log(cookie.indexOf(key));

        if (cookie.startsWith(key + "="))
        {
            // console.log("cookie found");
            // console.log(cookie);

            return cookie.substring((key + "=").length, cookie.length);
        }

        //while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        //if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }


    return "";
}


export function get_session_id()
{
    //check for unqiue id in local storage
    var session_id = local_storage__get_object(cache_keys.local_storage__session_id);
    //console.log("session_id", session_id);
    if (session_id)
    {
        return session_id;
    }


    //create new id
    session_id = get_guid();

    //save id
    var expiration_in_seconds = 60 * 60;
    local_storage__set_object(cache_keys.local_storage__session_id, session_id, expiration_in_seconds);

    //return new id
    return session_id;
}