import React from "react";

//redux related
import { connect } from "react-redux";
import { picks__list } from "../../../../redux/actions/picksActions";


import GamesHeader from "./../components/GamesHeader/GamesHeader";

import BlindPick from "./../components/PickMethods/BlindPick/BlindPick";
import QuickPick from "./../components/PickMethods/QuickPick/QuickPick";
import ReleaseToPickAll from "./../components/PickMethods/ReleaseToPickAll/ReleaseToPickAll";
import TapPick from "./../components/PickMethods/TapPick/TapPick";
import TapPickInfinite from "./../components/PickMethods/TapPickInfinite/TapPickInfinite";

import GamesList from "../../../../api/json/games.json";
import { get_url_title } from "../../../../api/utilities/strings";
import { track__pick_completed } from "../../../../api/utilities/analytics";
import { data__save_pick_locally, data__save_pick_to_server, data__create_pick_data } from "../../../../api/utilities/data";

//console.log(GamesList);

import "./../styles/games-details.scss";
import { meta_set } from "../../../../api/utilities/pages";

class GamesDetails extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            game: null,
            pick_details: null,
            pick_method: "",
            reload_pick_method: false,
            show_play_again: false,
            initial_page_loaded: false,
        };


        //dont use state for this to prevent endless loop
        this.initial_page_loaded = false;
        this.last_page_reload_ts = 0;
    }


    async componentDidMount()
    {
        //console.log("GameDetails: componentDidMount");        

        let id = "";
        if (this.props.match
            && this.props.match.params
            && this.props.match.params.id)
        {
            id = this.props.match.params.id;
        }
        //console.log(id);


        let game = null;
        if (id)
        {
            for (let item of GamesList)
            {
                if (get_url_title(item.name) === id)
                {
                    game = item;
                    //console.log("game", game);
                    break;
                }
            }
        }
        //console.log(game);

        if (game)
        {
            meta_set({
                title: game.name,
                description: "Find your lucky lotto numbers for " + game.name + ".",
                url_path: "/games/" + id + "/",
                image_url: "",
            });
        }


        this.setState({
            game: game,
        }, () =>
        {
            //this.load_pick_data();
        });


        //setTimeout(function () { window.scrollTo(0, 0); }, 100);
        //window.scrollTo(0, 0);

        setTimeout(() =>
        {
            try
            {
                //console.log("scroll to");
                let current_scroll_position_y = document.documentElement.scrollTop;

                // console.log("document.body.scrollTop", document.body.scrollTop);
                // console.log("document.documentElement.scrollTop", document.documentElement.scrollTop);
                //console.log("current_scroll_position_y", current_scroll_position_y);

                var rect = document.getElementById("games-header").getBoundingClientRect();
                //console.log(rect.top, rect.right, rect.bottom, rect.left);
                //console.log(document.getElementById("games-header").scrollTop);

                let scroll_to = current_scroll_position_y + rect.top;
                scroll_to -= 50;

                window.scrollTo(0, scroll_to);
            }
            catch (ex)
            {
                console.log(ex);
            }
        }, 100);
    }


    componentDidUpdate(previous_props, previous_state)
    {
        //console.log("GameDetails: componentDidUpdate", previous_props, previous_state);
        //console.log("GameDetails: componentDidUpdate");
        // console.log(previous_props);
        // console.log(this.props);
        // console.log("reload page????????????");
        if (this.initial_page_loaded)
        {
            if (this.props.history.action === "REPLACE")
            {
                if (this.props.location.pathname === previous_props.location.pathname)
                {
                    if (this.props.location.key !== previous_props.location.key)
                    {
                        //console.log("keys are different");
                        //key are different should be enough, will add ts sjust in case

                        let ts = new Date().getTime();
                        //console.log(ts - this.last_page_reload_ts);

                        //wait atleast 2 seconds
                        if (ts - this.last_page_reload_ts > 2000)
                        {
                            //console.log("reload page");
                            // console.log(previous_props);
                            // console.log(this.props);

                            this.last_page_reload_ts = new Date().getTime();


                            this.setState({
                                pick_method: "",
                                play_again: false,
                            });
                        }
                    }
                }
            }
            //let ts = this.last_page_reload_ts = new Date().getTime();

            //this.last_page_reload_ts = new Date().getTime();
            // console.log("reload page");
            // this.setState({
            //     select_pick_method: "",
            // });
        }
        else
        {
            //console.log("set initial page loaded");
            this.initial_page_loaded = true;
        }
    }


    // load_pick_data = async () =>
    // {
    //     console.log("load_pick_data");
    //     let saved_picks = load_saved_data_by_game_id(this.state.game.id);
    // }


    select_pick_method(pick_method, pick_details)
    {
        //console.log("select_pick_method", pick_method, pick_details);

        this.setState({
            pick_method: pick_method,
            reload_pick_method: false,
            show_play_again: false,
            pick_details: pick_details,
        });
    }


    onPick_completed = async (game, pick_method, first_line_picks, second_line_picks, rounds_completed) =>
    {
        //console.log("GameDetails: onPick_completed");
        //console.log(game, pick_method, first_line_picks, second_line_picks);

        let pick_data = data__create_pick_data(game, pick_method, first_line_picks, second_line_picks);
        //console.log(pick_data);


        //dont wait for data if round is complete, just show the play again links
        if (rounds_completed)
        {
            this.setState({
                show_play_again: true
            });
        }


        await data__save_pick_locally(pick_data);
        await data__save_pick_to_server(pick_data);


        //update redux        
        try
        {
            this.props.dispatch(picks__list());
        }
        catch (err)
        {
            console.error(err);
        }




        //from app
        // if (this.props.onNewPick)
        // {
        //     this.props.onNewPick();
        // }


        if (rounds_completed === false)
        {
        }
        else
        {
            // console.log("this.preferences__vibrate", this.state.preferences__vibrate);
            // if ((this.state.preferences__vibrate === undefined
            //     || this.state.preferences__vibrate === null
            //     || this.state.preferences__vibrate === true
            // )
            // )
            // {
            //     //console.log("vibration allowed");

            //     try
            //     {
            //         //console.log("vibrate");
            //         //wait, vibrate, wait, vibrate
            //         //Vibration.vibrate([0, 100, 100, 100, 100, 100, 100, 100, 100, 100], false)
            //     }
            //     catch (err)
            //     {
            //         console.log(err);
            //     }
            // }
            // else
            // {
            //     //console.log("vibration not allowed");
            // }
        }


        // if (rounds_completed) {
        //     this.setState({
        //         show_play_again: true
        //     });
        // }


        //analytics
        await track__pick_completed(game, pick_method);
    }


    onClick_play_again()
    {
        //console.log("onClick_play_again");

        this.setState({
            reload_pick_method: true,
        }, function ()
        {
            let self = this;
            setTimeout(function ()
            {
                //console.log("play again timeout");
                self.setState({
                    show_play_again: false,
                    reload_pick_method: false,
                });
            }, 100);
        });
    }


    render()
    {
        //console.log("render");

        if (!this.state.game)
        {
            return null;
        }


        return (
            <div className="page-game-details" >

                <GamesHeader game={this.state.game} tab="details" />

                {this.state.pick_method === "" &&
                    <div className="pick-types">
                        <button type="button" onClick={() => this.select_pick_method("blind pick", {})}>Blind Pick</button>
                        <button type="button" onClick={() => this.select_pick_method("tap pick", {})}>Tap Pick</button>
                        <button type="button" onClick={() => this.select_pick_method("tap pick infinite", {})}>Tap Pick Infinite</button>
                        <button type="button" onClick={() => this.select_pick_method("release to pick", {})}>Release to Pick</button>
                        <button type="button" onClick={() => this.select_pick_method("quick pick", {})}>Quick Pick</button>
                        <button type="button" onClick={() => this.select_pick_method("quick pick", { number_of_rounds: 10 })}>Quick Pick 10</button>
                    </div>
                }

                {this.state.reload_pick_method === false && this.state.pick_method === "blind pick" &&
                    <BlindPick game={this.state.game} pick_details={this.state.pick_details} onComplete={this.onPick_completed} preferences__vibrate={this.state.preferences__vibrate} />
                }

                {this.state.reload_pick_method === false && this.state.pick_method === "quick pick" &&
                    <QuickPick game={this.state.game} pick_details={this.state.pick_details} onComplete={this.onPick_completed} preferences__vibrate={this.state.preferences__vibrate} />
                }

                {this.state.reload_pick_method === false && this.state.pick_method === "tap pick" &&
                    <TapPick game={this.state.game} pick_details={this.state.pick_details} onComplete={this.onPick_completed} preferences__vibrate={this.state.preferences__vibrate} />
                }

                {this.state.reload_pick_method === false && this.state.pick_method === "tap pick infinite" &&
                    <TapPickInfinite game={this.state.game} pick_details={this.state.pick_details} onComplete={this.onPick_completed} preferences__vibrate={this.state.preferences__vibrate} />
                }

                {this.state.reload_pick_method === false && this.state.pick_method === "release to pick" &&
                    <ReleaseToPickAll game={this.state.game} pick_details={this.state.pick_details} onComplete={this.onPick_completed} preferences__vibrate={this.state.preferences__vibrate} />
                }


                {this.state.pick_method !== "" && this.state.reload_pick_method === false && this.state.show_play_again === true &&
                    <div className="after-pick-links">
                        <div>
                            <span onClick={() => { this.onClick_play_again(); }}>Pick Again</span>
                        </div>
                        <div>
                            <span onClick={() => { this.setState({ pick_method: "" }) }}>Change Pick Type</span>
                        </div>
                    </div>
                }

            </div >
        );
    }
}
//export default GamesDetails
//these are the state properties you want access to
//these will get added to this.props
const mapStateToProps = state => ({
    picks: state.picks.items
});


export default connect(mapStateToProps)(GamesDetails);