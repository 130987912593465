import React, { Component } from "react";

//import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import GamePicks from "../../../../../components/GamePicks/GamePicks";

import { get_random_numbers_by_game } from "../../../../../../api/utilities/from-app/games";
//import { random__get_range } from "../../../../../../api/utilities/from-app/math";
import pick_methods from "../../../../../../api/utilities/from-app/enums/pick_methods.json";
//import * as globals from "../../../../../../api/globals";


import "./blind-pick.scss";


export default class BlindPick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number_of_rows: 0,
            number_of_columns: 0,
            cards_per_row: 0,
            column_flex_size: 0,
            card_aspect_ratio: 1,
            row_height: 0,
            picks_made: 0,
            current_number: 0,
            current_line: "",
            current_line_unique_numbers: true,
            first_line_picks: [],
            second_line_picks: [],
            first_line_numbers: [],
            second_line_numbers: [],
            game_over: false,
            loading: true,

            completed_rounds: 0,
        };

        this.pick_method = pick_methods.blind_cards;

        //this is state but we need to ensure its value is set immediately
        this.ready_for_next_pick = false;
        this.picks_saved = false;

        //delay - prevents (hopefully) from hitting the next round of numbers accidentally
        this.delay_before_loading_line = 400;
    }


    componentDidMount() {
        //console.log("BlindPick: componentDiMount", this.props.game);
        this.load_data();
    }


    componentWillUnmount() {

    }


    load_data = () => {
        //console.log("BlindPick: load_data");
        //console.log("BlindPick: load_data", this.props.game);

        let self = this;


        //get a random list of first line and second line numbers        
        var rnd_numbers = get_random_numbers_by_game(this.props.game);
        // console.log("rnd_numbers", rnd_numbers);        
        // console.log("rnd_numbers", rnd_numbers.first_line_numbers.length, rnd_numbers.second_line_numbers.length);


        //create objects for line numbers
        let first_line_numbers = [];
        for (let number_index = 0; number_index < rnd_numbers.first_line_numbers.length; number_index++) {
            first_line_numbers.push(
                {
                    number: rnd_numbers.first_line_numbers[number_index],
                    is_selected: false,
                });
        }

        let second_line_numbers = [];
        for (let number_index = 0; number_index < rnd_numbers.second_line_numbers.length; number_index++) {
            second_line_numbers.push(
                {
                    number: rnd_numbers.second_line_numbers[number_index],
                    is_selected: false,
                });
        }


        this.setState({
            rnd_numbers: rnd_numbers,
            first_line_numbers: first_line_numbers,
            second_line_numbers: second_line_numbers,
            game_over: false,
            picks_saved: false,
        }, function () {
            //starts the process - with slight delay           
            setTimeout(function () { self.load_line_data("primary"); }, this.delay_before_loading_line);
        });
    }


    load_line_data = (line_type) => {
        //console.log("load_line_data: " + line_type);


        let current_line_unique_numbers = this.props.game.first_line.unique_numbers;

        //number of cards
        let number_of_cards = this.state.first_line_numbers.length;
        if (line_type === "secondary") {
            number_of_cards = this.state.second_line_numbers.length;
            current_line_unique_numbers = this.props.game.second_line.unique_numbers;
        }


        let screen_size = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        //console.log("screen_size", screen_size);
        //screen_size.height -= 100;
        //console.log("screen_size", screen_size);


        //get board height for this screen        
        let screen_resized__board_height = screen_size.height - 150;
        let screen_resized__board_width = screen_size.width;
        // console.log("screen_resized__board_width", screen_resized__board_width);
        // console.log("screen_resized__board_height", screen_resized__board_height);


        let board_height_ratio = screen_resized__board_height / screen_resized__board_width;
        let board_width_ratio = screen_resized__board_width / screen_resized__board_height;
        // console.log("board_height_ratio", board_height_ratio);
        // console.log("board_width_ratio", board_width_ratio);


        let optimized_number_of_columns = 0;
        let optimized_number_of_rows = 0;
        let optimized_row_column_ratio = 100;
        for (let number_of_columns = 1; number_of_columns <= 10; number_of_columns++) {
            let number_of_rows = Math.ceil(number_of_cards / number_of_columns);
            let row_col_ratio = (board_width_ratio * number_of_rows) / (board_height_ratio * number_of_columns);
            row_col_ratio = Math.abs(1 - row_col_ratio);

            // console.log("number_of_rows", number_of_rows);
            // console.log("number_of_columns", number_of_columns);
            // console.log("row_col_ratio", row_col_ratio);
            // console.log("optimized_row_column_ratio", optimized_row_column_ratio);

            if (row_col_ratio < optimized_row_column_ratio) {
                //console.log("new optimized ratio", row_col_ratio);

                optimized_number_of_columns = number_of_columns;
                optimized_number_of_rows = number_of_rows;
                optimized_row_column_ratio = row_col_ratio;
            }
        }
        // console.log("optimized_number_of_rows", optimized_number_of_rows);
        // console.log("optimized_number_of_columns", optimized_number_of_columns);
        //console.log("optimized_row_column_ratio", optimized_row_column_ratio);





        // // determine flex width
        // optimized_number_of_columns = 10;
        // if (number_of_cards == 10)
        // {
        //     optimized_number_of_columns = 5; 
        // }
        // else if (number_of_cards == 10)
        // {
        //     optimized_number_of_columns = 5; 
        // }
        // else if (number_of_cards == 80)
        // {
        //     optimized_number_of_columns = 8; 
        // }

        let column_flex_size = this.state.column_flex_size;
        column_flex_size = 1 / optimized_number_of_columns;
        column_flex_size *= 100;
        //console.log("column_flex_size", column_flex_size);       

        let card_aspect_ratio = 1;


        let row_height = screen_resized__board_height / optimized_number_of_rows;
        //console.log("row_height", row_height);


        this.setState({
            number_of_rows: optimized_number_of_rows,
            number_of_columns: optimized_number_of_columns,
            number_of_cards: number_of_cards,
            column_flex_size: column_flex_size,
            row_height: row_height,
            card_aspect_ratio: card_aspect_ratio,
            current_line: line_type,
            current_line_unique_numbers: current_line_unique_numbers,
        }, function () { this.load_line(line_type); });
    }


    load_line = (line_type) => {
        this.setState({
            current_line: line_type,
            loading: false,
        }, function () {
            this.ready_for_next_pick = true;
        });
    }


    make_pick = (selected_card) => {
        // console.log("make_pick", selected_card);
        // console.log(this.state.current_line);

        //console.log(this.props.game);
        if (this.state.current_line === "primary") {
            if (this.props.game.first_line.unique_numbers === true && selected_card.is_selected) {
                //console.log("card already selected");

                //remove from picks
                let first_line_picks = this.state.first_line_picks;
                //console.log(first_line_picks);

                let number_index = first_line_picks.indexOf(selected_card.number);
                if (number_index > -1) {
                    first_line_picks.splice(number_index, 1); // 2nd parameter means remove one item only
                }


                //set card selected = false
                let first_line_numbers = this.state.first_line_numbers;
                for (let number of first_line_numbers) {
                    if (number.number === selected_card.number) {
                        number.is_selected = false;
                    }
                }

                this.setState({
                    first_line_numbers: first_line_numbers,
                    first_line_picks: first_line_picks,
                });


                return;
            }
        }
        else if (this.state.current_line === "secondary") {
            if (this.props.game.second_line.unique_numbers === true && selected_card.is_selected) {
                //console.log("card already selected");

                //remove from picks
                let second_line_picks = this.state.second_line_picks;
                //console.log(second_line_picks);

                let number_index = second_line_picks.indexOf(selected_card.number);
                if (number_index > -1) {
                    second_line_picks.splice(number_index, 1); // 2nd parameter means remove one item only
                }


                //set card selected = false
                let second_line_numbers = this.state.second_line_numbers;
                for (let number of second_line_numbers) {
                    if (number.number === selected_card.number) {
                        number.is_selected = false;
                    }
                }

                this.setState({
                    second_line_numbers: second_line_numbers,
                    second_line_picks: second_line_picks,
                });


                return;
            }
        }

        if (this.ready_for_next_pick === false) {
            console.log("this.ready_for_next_pick: false");
            return;
        }


        if (this.state.game_over) {
            //this is used to ensure additional picks are not made between game over and timer stopping
            console.log("game over");
            return;
        }


        let self = this;

        //console.log(this.state);
        this.ready_for_next_pick = false;


        let first_line_picks = this.state.first_line_picks;
        let second_line_picks = this.state.second_line_picks;

        let first_line_numbers = this.state.first_line_numbers;
        let second_line_numbers = this.state.second_line_numbers;


        let next_current_line = this.state.current_line;
        if (this.state.current_line === "primary") {
            //mark number as selected
            for (let number of first_line_numbers) {
                if (number.number === selected_card.number) {
                    number.is_selected = true;
                    first_line_picks.push(number.number);
                }
            }


            if (first_line_picks.length >= this.props.game.first_line.number_of_picks) {
                next_current_line = "secondary";
            }
        }
        else if (this.state.current_line === "secondary") {
            //mark number as selected
            for (let number of second_line_numbers) {
                if (number.number === selected_card.number) {
                    number.is_selected = true;
                    second_line_picks.push(number.number);
                }
            }
        }



        //game over:
        let game_over = false;
        if (first_line_picks.length >= this.props.game.first_line.number_of_picks
            && second_line_picks.length >= this.props.game.second_line.number_of_picks
        ) {
            //game over
            //console.log("game over");
            game_over = true;
        }


        // console.log("current_line", this.state.current_line);
        // console.log("next_current_line", next_current_line);
        let loading = this.state.loading;
        let reload_line_data = false;

        if (next_current_line === "secondary"
            && next_current_line !== this.state.current_line) {
            loading = true;
        }
        else if (this.state.current_line_unique_numbers === false) {
            reload_line_data = true;
            loading = true;
        }


        this.setState({
            game_over: game_over,
            first_line_picks: first_line_picks,
            second_line_picks: second_line_picks,
            first_line_numbers: first_line_numbers,
            second_line_numbers: second_line_numbers,
            loading: loading,
        },
            function () {
                if (game_over) {
                    this.save_picks();
                }
                else {
                    // console.log("current_line", this.state.current_line);
                    // console.log("next_current_line", next_current_line);

                    this.ready_for_next_pick = true;

                    //if loading, reload current line or load next line
                    if (reload_line_data) {
                        self.load_data();
                    }
                    else if (loading) {
                        setTimeout(function () { self.load_line_data(next_current_line); }, self.delay_before_loading_line);
                    }
                }
            }
        );
    }


    save_picks = async () => {
        //console.log("BlindPick: save_picks");

        if (this.picks_saved) {
            console.log("picks already saved");
            return;
        }


        this.picks_saved = true;

        //console.log("stop timer");
        if (this.timer) {
            clearTimeout(this.timer);
        }


        //are we done?
        var rounds_completed = true;
        var completed_rounds = this.state.completed_rounds;
        completed_rounds++;

        if (completed_rounds < this.number_of_rounds) {
            rounds_completed = false;
        }
        // console.log("Start new round? " + completed_rounds);
        // console.log("rounds_completed? " + rounds_completed);


        //save pick
        await this.props.onComplete(this.props.game, this.pick_method, this.state.first_line_picks, this.state.second_line_picks, rounds_completed);


        // console.log("completed_rounds: " + completed_rounds);
        // console.log("this.number_of_rounds: " + this.number_of_rounds);
        if (completed_rounds < this.number_of_rounds) {
            this.setState({
                completed_rounds: completed_rounds
            }, function () {
                // if (rounds_completed === false)            
                // {
                //     //console.log("Start a new round");
                //     this.start_picker();
                // }
            })
        }
    }


    render() {
        //console.log("BlindPick: render", this.state);
        //console.log("BlindPick: render: column_flex_size", this.state.column_flex_size);
        // console.log("BlindPick: render", this.state.first_line_numbers);
        // console.log("BlindPick: render", this.state.second_line_numbers);
        // console.log("BlindPick: render", this.state.first_line_picks);
        // console.log("BlindPick: render", this.state.second_line_picks);


        let current_row_numbers = [];
        if (this.state.current_line === "primary") {
            current_row_numbers = this.state.first_line_numbers;
        }
        else if (this.state.current_line === "secondary") {
            current_row_numbers = this.state.second_line_numbers;
        }


        //let aspect_ratio = this.state.card_aspect_ratio.toFixed(2);
        //console.log("aspect_ratio", aspect_ratio);

        let card_rows_ui = [];
        if (current_row_numbers.length > 0) {
            let card_index = 0;
            for (let row_index = 0; row_index < this.state.number_of_rows; row_index++) {
                let card_columns_ui = [];
                for (let row_card_index = 0; row_card_index < this.state.number_of_columns; row_card_index++) {
                    if (card_index < current_row_numbers.length) {
                        let number = current_row_numbers[card_index];
                        //console.log(number);


                        card_columns_ui.push(
                            <div
                                key={number.number}
                                className="card-wrapper"
                                style={{ flexBasis: this.state.column_flex_size.toString() + "%" }}
                                data-selected={number.is_selected && this.state.current_line_unique_numbers === true ? "true" : "false"}
                                data-ball-type={this.state.current_line}
                            >
                                <div
                                    className="card"
                                    style={{ height: this.state.row_height, }}
                                    onClick={() => this.make_pick(number)}
                                    key={row_index.toString() + "-" + number.number.toString()}>
                                </div>
                            </div>
                        );

                        card_index++;
                    }
                }

                card_rows_ui.push(card_columns_ui);
                // card_rows_ui.push(
                //     <div className="row-line-old" key={this.state.current_line + " - " + row_index.toString()}>
                //         {card_columns_ui}
                //     </div>
                // );
            }
        }
        //console.log(card_data_ui.length);


        let status_text = "";
        if (this.state.current_line === "primary") {
            status_text = this.state.first_line_picks.length.toString() + " / " + this.props.game.first_line.number_of_picks;
        }
        else if (this.state.current_line === "secondary") {
            status_text = this.state.second_line_picks.length.toString() + " / " + this.props.game.second_line.number_of_picks;
        }

        return (
            <div className="pick-method-blind">

                {this.state.game_over === false &&
                    <>
                        {status_text !== "" &&
                            <div className="status-text">{status_text}</div>
                        }

                        {this.state.loading === false &&
                            <div className="row-line">
                                {card_rows_ui}
                            </div>
                        }
                    </>
                }

                {this.state.game_over === true &&
                    <div className="game-over-game-picks">
                        <GamePicks game={this.props.game} first_line_picks={this.state.first_line_picks} second_line_picks={this.state.second_line_picks} />
                    </div>
                }
            </div>
        );
    }
}