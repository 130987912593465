import React from "react";

import GamesHeader from "./../components/GamesHeader/GamesHeader";
import GamePicks from "../../../components/GamePicks/GamePicks";

import GamesList from "../../../../api/json/games.json";
import { get_url_title } from "../../../../api/utilities/strings";
import * as dates from "../../../../api/utilities/from-app/dates";

//console.log(GamesList);

import "./../styles/games-details.scss";
import { meta_set } from "../../../../api/utilities/pages";


class GamesRecentWinningNumbers extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            game: null,
            winning_numbers: [],
            failed: false,
        };
    }


    async componentDidMount()
    {        
        let id = "";
        if (this.props.match
            && this.props.match.params
            && this.props.match.params.id)
        {
            id = this.props.match.params.id;
        }
        //console.log(id);


        let game = null;
        if (id)
        {
            for (let item of GamesList)
            {
                if (get_url_title(item.name) === id)
                {
                    game = item;
                    break;
                }
            }
        }
        //console.log(game);


        //winning numbers     
        if (game)
        {
            if (game.winning_numbers__recent_url)
            {
                fetch(game.winning_numbers__recent_url)
                    .then(res => res.json())
                    .then(
                        (result) =>
                        {
                            this.setState({
                                winning_numbers: result,
                            });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) =>
                        {
                            this.setState({
                                failed: true,
                            });
                        }
                    )
            }
        }


        this.setState({
            game: game,
        });
    }


    render()
    {
        //console.log(this.state);


        if (!this.state.game)
        {
            return null;
        }

        
        meta_set({
            title: this.state.game.name + ": Recent Winning Numbers",
            description: "Recent winning numbers for " + this.state.game.name + ".",
            url_path: "/games/" + get_url_title(this.state.game.name) + "/recent-winning-numbers/",
            image_url: "",
        });



        return (
            <div className="page-game-recent-winning-numbers" >
                <GamesHeader game={this.state.game} tab="recent winning numbers" />

                {this.state.winning_numbers.length > 0 &&
                    <div className="winning-numbers-list">
                        {this.state.winning_numbers.map((item) => 
                        {
                            //console.log(item);

                            let draw_date = new Date(item.draw_date);
                            let display_date = dates.dates__format__US__LOCAL_TIME(draw_date);
                            if (item.display_date)
                            {
                                display_date = item.display_date;
                            }

                            return (
                                <div key={item.draw_date} className="winning-numbers-item">
                                    <div className="winning-numbers-date">{display_date}</div>
                                    <GamePicks key={item.draw_date} game={this.state.game} first_line_picks={item.first_line_numbers} second_line_picks={item.second_line_numbers} />
                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div >
        );
    }
}
export default GamesRecentWinningNumbers