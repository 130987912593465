
import { meta_set } from "../../../api/utilities/pages";
import "./feedback.scss";

export default function Feedback()
{
    meta_set({
        title: "Feedback",
        description: "Have we made a mistake? Let us know.",
        url_path: "/feedback/",
        image_url: "",
    });

    return (
        <div className="page-feedback">
            <h1>Feedback</h1>

            <iframe width="100%" height="800" title="Lucky Lotto Number Picker Feedback" src="https://s.surveyplanet.com/2ss6la2w" />
        </div>
    )
}