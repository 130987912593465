import React from "react";
//import { Suspense } from "react";
import { Route, Switch } from 'react-router-dom';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';


//pages
import Home from './ui/pages/Home/Home';
import MainApp from "./MainApp";


//api
// import * as browser_functions from "./api/utilities/browser";
// import firebase_config from "./_config/firebase-config";


//const firebase = window.firebase;


class App extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            site_ready: false,
            require_login: false,
        };
    }

    async componentDidMount()
    {
        this.setState(
            {
                site_ready: true,
            }
        );
    }

    componentDidCatch(error, errorInfo)
    {
        console.log("App.js:", "componentDidCatch", error);
    }

    render()
    {
        if (this.state.site_ready === false)
        {
            return (null)
        }


        return (
            <div className="app">                                
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route component={MainApp} />
                </Switch>
            </div>
        )
    }
}

// const mapStateToProps = state => ({    
//     site_categories: state.categories.site_categories,
//     users_authentication: state.users_authentication
// });

export default App;
//export default withRouter(App);
//export default withRouter(connect(mapStateToProps)(App));
















// //https://play.google.com/store/apps/details?id=com.lhmgapps.luckylottonumberpicker
// //https://lottopicker.onelink.me/Q5QU/4dc266ca

// function App()
// {
//     return (
//         <div className="App">
//             <header className="App-header">
//                 <img src="/images/lucky-lotto-number-picker--website--title-01.png" className="App-title" alt="Lucky Lotto Number Picker" title="Lucky Lotto Number Picker" />
//                 <img src="/images/lucky-lotto-number-picker--website--logo-01.png" className="App-logo" alt="Lucky Lotto Number Picker Logo" title="Lucky Lotto Number Picker Logo" />
//                 <p className="App-description">
//                     Pick Your Winning Lottery Numbers Today!
//                 </p>
//                 <div className="App-links">
//                     <a href="https://lottopicker.onelink.me/Q5QU/4dc266ca" target="_blank" rel="noreferrer" alt="com.lhmgapps.luckylottonumberpicker" title="com.lhmgapps.luckylottonumberpicker">
//                         <img src="/images/mobile-stores--android.png" alt="Lucky Lotto Number Picker in the Google Play Store" title="Lucky Lotto Number Picker in the Google Play Store" />
//                     </a>
//                 </div>
//             </header>
//         </div>
//     );
// }

// export default App;
